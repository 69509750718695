import durationInMinutes from "@/utils/helpers/durationInMinutes";
import { formatDuration } from "@/utils/helpers/formatDuration";
import { UserResponse } from "@/utils/types/search-data.type";
import { useCallback } from "react";
import replaceHtmlQuotes from "@/utils/helpers/replaceHtmlQuotes";
import isYouTubeLink from "../helpers/isYouTubeLink";
import extractVideoIdFromLink from "../helpers/extractVideoIdFromLink";
import fetchVideoInfo from "../helpers/fetchVideoInfo";
import toast from "react-hot-toast";
import { VideoItem } from "@/utils/types/video-item.type";

interface UseSearchYoutubeProps {
  searchTerm: string;
  setResults: React.Dispatch<React.SetStateAction<VideoItem[]>>; // Adjust the type based on your results state
}

const useSearchYoutube = ({
  searchTerm,
  setResults,
}: UseSearchYoutubeProps) => {
  const searchYouTube = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | undefined) => {
      e?.preventDefault();

      const performSearch: any = async (apiKey: string | undefined) => {
        try {
          const searchUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${searchTerm}&key=${apiKey}&type=video&maxResults=30`;
          const searchResponse = await fetch(searchUrl);
          const searchData: UserResponse = await searchResponse.json();

          const videoIds = searchData.items.map((item) => item.id.videoId);

          const detailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,statistics&id=${videoIds.join(
            ","
          )}&key=${apiKey}`;
          const detailsResponse = await fetch(detailsUrl);
          const detailsData = await detailsResponse.json();

          if (detailsData.error) {
            throw new Error(detailsData.error.message);
          }

          return searchData.items.map((item) => {
            const details = detailsData.items.find(
              (detail: any) => detail.id === item.id.videoId
            );
            const duration = formatDuration(details?.contentDetails?.duration);
            const durationMins = durationInMinutes(
              details?.contentDetails?.duration
            );

            // Check if the video is age restricted
            const isAgeRestricted =
              details?.contentDetails?.contentRating?.ytRating ===
              "ytAgeRestricted";

            if (durationMins > 20 || durationMins === 0 || isAgeRestricted)
              return null;

            return {
              id: item.id.videoId,
              title: replaceHtmlQuotes(item.snippet.title),
              channelTitle: replaceHtmlQuotes(item.snippet.channelTitle),
              duration,
              views: details?.statistics?.viewCount,
            };
          });
        } catch (error) {
          if (apiKey !== process.env.REACT_APP_API_KEY_FALLBACK) {
            console.log("Trying with fallback API key");
            return await performSearch(process.env.REACT_APP_API_KEY_FALLBACK);
          } else {
            throw error;
          }
        }
      };

      if (isYouTubeLink(searchTerm)) {
        const videoId = extractVideoIdFromLink(searchTerm);
        if (videoId) {
          try {
            const videoInfo = await fetchVideoInfo(videoId);

            if (!videoInfo) {
              toast.error(
                "The video you are trying to add is too long or too short or 18+"
              );
              return;
            }
            setResults([videoInfo]);
          } catch (error) {
            console.error("Error fetching video info:", error);
            toast.error("Error fetching video info.");
          }
        } else {
          toast.error("Invalid YouTube URL.");
        }
      } else {
        try {
          const resultsWithDetails = await performSearch(
            process.env.REACT_APP_API_KEY
          );
          if (
            resultsWithDetails.filter((result: VideoItem) => result !== null)
          ) {
            toast.success(
              "Search completed successfully but some results were filtered out due to their duration."
            );
          }
          setResults(
            resultsWithDetails.filter((result: VideoItem) => result !== null)
          );
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
          toast.error("The request cannot be completed due to an error.");
        }
      }
    },
    [searchTerm]
  );

  return searchYouTube;
};

export default useSearchYoutube;
