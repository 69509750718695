const parseDurationToMilliseconds = (durationStr: string) => {
  const parts = durationStr.split(":").map(Number);
  let milliseconds = 0;

  if (parts.length === 2) {
    milliseconds = parts[0] * 60 * 1000 + parts[1] * 1000;
  }

  return milliseconds || 20000;
};

export default parseDurationToMilliseconds;
