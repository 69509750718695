import { Dispatch, FC, MutableRefObject, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import DefaultTab from "@/Components/UI/DefaultTab/DefaultTab";
import QueueTabsEnum from "@/utils/enums/queue-tabs.enum";
import PlayerList from "@/Components/VideoPlayer/Queue/QueueTabs/PlayerList/PlayerList";
import RecentlyPlayed from "@/Components/VideoPlayer/Queue/QueueTabs/RecentlyPlayed/RecentlyPlayed";
import Leaderboard from "@/Components/VideoPlayer/Queue/QueueTabs/Leaderboard/Leaderboard";
import History from "@/Components/VideoPlayer/Queue/QueueTabs/History/History";
import { VideoItem } from "@/utils/types/video-item.type";

interface QueueTabsProps {
  activeTab: QueueTabsEnum;
  setActiveTab: Dispatch<React.SetStateAction<QueueTabsEnum>>;
  recentlyPlayedQueue: VideoItem[];
  videoQueue: VideoItem[];
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void;
  updateVideoQueue: (newQueue: VideoItem[], isEnd?: boolean) => void;
  handleLike: (video: VideoItem) => void;
  handleDislike: (video: VideoItem) => void;
  onVideoSelect: (video: VideoItem) => void;
  socketRef: MutableRefObject<Socket | null>;
  serverEndpoint: string | undefined;
  playerRef: any;
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}

const QueueTabs: FC<QueueTabsProps> = ({
  activeTab,
  setActiveTab,
  recentlyPlayedQueue,
  videoQueue,
  setVideoQueue,
  updateVideoQueue,
  handleLike,
  handleDislike,
  onVideoSelect,
  serverEndpoint,
  socketRef,
  playerRef,
  scrollableParent,
}) => {
  return (
    <>
      <div className="flex gap-2 mt-8 overflow-auto scrollbar-none min-h-[44px] items-center pl-1">
        {Object.values(QueueTabsEnum).map((value, id) => {
          return (
            <span key={id} className="text-nowrap">
              <DefaultTab
                onClick={() => setActiveTab(QueueTabsEnum[value])}
                active={activeTab === QueueTabsEnum[value]}
              >
                {value.replaceAll("_", " ")}
              </DefaultTab>
            </span>
          );
        })}
      </div>
      {activeTab === QueueTabsEnum.UP_NEXT && (
        <PlayerList
          videoQueue={videoQueue.slice(1)}
          updateVideoQueue={updateVideoQueue}
          handleLike={handleLike}
          handleDislike={handleDislike}
          setVideoQueue={setVideoQueue}
          serverEndpoint={serverEndpoint}
          socketRef={socketRef}
          playerRef={playerRef}
          scrollableParent={scrollableParent}
        />
      )}
      {activeTab === QueueTabsEnum.RECENTLY_PLAYED && (
        <RecentlyPlayed
          recentlyPlayedQueue={recentlyPlayedQueue}
          updateVideoQueue={updateVideoQueue}
          handleLike={handleLike}
          handleDislike={handleDislike}
          setVideoQueue={setVideoQueue}
          serverEndpoint={serverEndpoint}
          socketRef={socketRef}
          playerRef={playerRef}
          scrollableParent={scrollableParent!}
          onVideoSelect={onVideoSelect}
        />
      )}
      {activeTab === QueueTabsEnum.LEADERBOARD && (
        <Leaderboard scrollableParent={scrollableParent} />
      )}
      {activeTab === QueueTabsEnum.HISTORY && (
        <History scrollableParent={scrollableParent} />
      )}
    </>
  );
};

export default QueueTabs;
