import { FC, MutableRefObject, useContext, useRef } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import { useLimitResults } from "@/utils/hooks/useLimitResults";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import { ArrowUpIcon } from "@/assets/svg/svg";
import LeaderboardListItem from "./LeaderBoardListItem/LeaderBoardListItem";

interface LeaderboardListProps {
  items: (UserSpotlightItem | VideoItem)[];
  metricText: string;
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}

const LeaderboardList: FC<LeaderboardListProps> = ({
  items,
  metricText,
  scrollableParent,
}) => {
  const { mode } = useContext(ThemeContext);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const { displayedItems, isExpanded, handleShowMore, handleBackToTop } =
    useLimitResults(items, 7);

  return (
    <div className="mt-6" ref={scrollableRef}>
      <ul>
        {displayedItems.map((item, id) => (
          <li
            key={id}
            className={`flex items-center py-4 ${
              mode === "dark"
                ? id < items.length - 1
                  ? "text-neutral-white border-b-[1px] border-b-solid border-b-accent-gray300"
                  : "text-neutral-white"
                : id < items.length - 1
                ? "text-primary-blackPetrol border-b-[1px] border-b-solid border-b-accent-gray100"
                : "text-primary-blackPetrol"
            }`}
          >
            <LeaderboardListItem item={item} id={id} metricText={metricText} />
          </li>
        ))}
        {displayedItems.length < items.length && (
          <DefaultButton onClick={handleShowMore}>SHOW MORE</DefaultButton>
        )}
      </ul>
      {isExpanded && (
        <div className="back-to-top sticky bottom-0 right-0 w-2.5 z-10 ml-auto">
          <span
            className="cursor-pointer inline-block relative"
            style={{ top: "1.25rem" }}
            onClick={() => handleBackToTop(scrollableParent)}
          >
            <ArrowUpIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default LeaderboardList;
