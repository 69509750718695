import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import { FC, useContext } from "react";

interface LeaderboardListItemProps {
  item: UserSpotlightItem | VideoItem;
  id: number;
  metricText: string;
}

const LeaderboardListItem: FC<LeaderboardListItemProps> = ({
  item,
  id,
  metricText,
}) => {
  const { mode } = useContext(ThemeContext);

  return (
    <>
      <span
        className="inline-flex items-center justify-center mr-4 md:mr-8 w-[1.75rem] h-[1.75rem] text-center font-bold rounded-full shrink-0"
        style={{
          backgroundColor: mode === "dark" ? "#ffffff1a" : "#E8E8E8",
        }}
      >
        {id + 4}
      </span>
      {(item as UserSpotlightItem).avatar && (
        <img
          className="w-[2.25rem] h-[2.25rem] md:w-[2.75rem] md:h-[2.75rem] rounded-full mr-3 md:mr-5"
          src={(item as UserSpotlightItem).avatar}
          alt={(item as UserSpotlightItem).name}
        />
      )}
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div>
          <h2 className="font-bold">
            {(item as UserSpotlightItem).name || (item as VideoItem).title}
          </h2>
          <p className="text-[14px]">
            {metricText + " "}
            <b>
              {(item as UserSpotlightItem).value || (item as VideoItem).likes!}
            </b>
          </p>
        </div>
        {(item as VideoItem).title && (
          <div className="flex items-center items-center gap-x-4 mt-2 md:mt-0 md:pl-1">
            <ActionBar
              video={item as VideoItem}
              tooltipId={TooltipTypeEnum.LEADERBOARDLIST}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LeaderboardListItem;
