import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import Heading from "@/Components/UI/Heading/Heading";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import cropText from "@/utils/helpers/cropText";
import { VideoItem } from "@/utils/types/video-item.type";
import { FC } from "react";

const YouTubeSearchResult: FC<{ result: VideoItem }> = ({ result }) => {
  return (
    <>
      <div className="flex gap-3 items-center">
        <ActionBar video={result} tooltipId={TooltipTypeEnum.SEARCH} />
      </div>

      <div>
        <Heading type={HeadingTypeEnum.h3_Default}>
          {cropText(result.title)}
        </Heading>
        <Paragraph
          type={ParagraphTypeEnum.p2_Default}
          className="result-duration"
        >
          {result.channelTitle &&
            "Channel: " + cropText(result.channelTitle, 25)}
          <br />
          Length: {result.duration}
        </Paragraph>
      </div>
    </>
  );
};

export default YouTubeSearchResult;
