import { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import classNames from "classnames";

interface BurgerMenuProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ opened, setOpened }) => {
  const { mode } = useContext(ThemeContext);

  return (
    <div
      className={classNames("tham tham-e-squeeze tham-w-6 md:hidden", {
        "tham-active": opened,
      })}
      onClick={() => setOpened(!opened)}
    >
      <div className="tham-box">
        <div
          className={`tham-inner ${
            mode === "dark" ? "bg-neutral-white" : "bg-background-bgDark300"
          }`}
        />
      </div>
    </div>
  );
};

export default BurgerMenu;
