import { FC } from "react";
import Skeleton from "react-loading-skeleton";

const DesktopSkeleton: FC = () => {
  return (
    <div className="items-center gap-4 hidden md:flex w-full justify-between md:h-[40px]">
      <Skeleton style={{ width: "151px", height: "27px" }} />
      <div className="flex items-center gap-4">
        <Skeleton
          count={2}
          style={{ width: "4.25rem", height: "2rem" }}
          containerClassName="inline-flex items-center gap-2 w-[9.25rem]"
        />
        <Skeleton
          style={{ width: "2.25rem", height: "2.25rem" }}
          circle={true}
        />
        <div className="flex flex-col items-start">
          <Skeleton style={{ width: "5rem", height: "1.125rem" }} />
          <Skeleton style={{ width: "2.5rem", height: "1rem" }} />
        </div>
      </div>
    </div>
  );
};

export default DesktopSkeleton;
