import { FC, MutableRefObject } from "react";
import Loader from "@/Components/UI/Loader/Loader";
import SpotlightStatsEnum from "@/utils/enums/spotlight-stats-type.enum";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import LeaderboardSpotlight from "./LeaderboardSpotlight/LeaderboardSpotlight";
import LeaderboardList from "./LeaderboardList/LeaderboardList";

interface LeaderboardContentProps {
  isLoading: boolean;
  topThree: (UserSpotlightItem | VideoItem)[] | null;
  data: (UserSpotlightItem | VideoItem)[] | null;
  dataType: string;
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}

const LeaderboardContent: FC<LeaderboardContentProps> = ({
  isLoading,
  topThree,
  data,
  dataType,
  scrollableParent,
}) => {
  const metricText =
    dataType === SpotlightStatsEnum.USER_MOST_SONGS
      ? "Songs added:"
      : "Likes received:";

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {topThree && (
            <LeaderboardSpotlight items={topThree} metricText={metricText} />
          )}
          {data && (
            <LeaderboardList
              items={data}
              metricText={metricText}
              scrollableParent={scrollableParent}
            />
          )}
        </>
      )}
    </>
  );
};

export default LeaderboardContent;
