import React, { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";

interface DefaultTabProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  active?: boolean;
}

const stylesByType = {
  light: {
    default: "text-accent-gray300 bg-neutral-white",
    hover: "hover:bg-accent-gray50 hover:drop-shadow-primary",
    focus: "focus:ring-offset-1 focus:ring-2 focus:ring-button-btnLight",
    active: "text-primary-blackPetrol !bg-accent-gray50",
  },
  dark: {
    default: "text-neutral-white bg-background-bgDark100",
    hover: "hover:bg-button-btnDarkHover",
    focus: "focus:ring-offset-2 focus:ring-2 focus:ring-button-btnDark",
    active: "!bg-button-btnDark",
  },
};

const DefaultTab: FC<DefaultTabProps> = ({
  children,
  className,
  active = false,
  ...props
}) => {
  const { mode } = useContext(ThemeContext);

  const getButtonClasses = () => {
    const baseClasses =
      "flex justify-center items-center font-nunitoSans font-bold w-auto px-3 py-2 rounded-[5px]";
    const modeClasses = `${stylesByType[mode].default} ${stylesByType[mode].hover} ${stylesByType[mode].focus}`;
    const activeClass = active ? stylesByType[mode].active : "";

    return `${baseClasses} ${modeClasses} ${activeClass} ${className || ""}`;
  };

  return (
    <button className={getButtonClasses()} {...props}>
      {children}
    </button>
  );
};

export default DefaultTab;
