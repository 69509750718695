import { VideoItem } from "@/utils/types/video-item.type";
import axios from "axios";
import toast from "react-hot-toast";

const beLogin = async (
  email: string,
  picture: string | undefined,
  signName: string | undefined,
  setUser: (user: string | null) => void,
  setPicture: (picture: string | undefined) => void,
  setRole: (role: string | undefined) => void,
  setFavoriteUserList: (list: VideoItem) => void,
  setName: (name: string | undefined) => void
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/google`,
      {
        email: email,
        role: "user",
        signName: signName,
        picture: picture,
      }
    );
    setUser(email);
    setPicture(picture);
    setRole(response.data.user.role);
    setFavoriteUserList(response.data.user.favoriteVideos);
    setName(signName);

    localStorage.setItem("jwt", response.data.user.jwt);
  } catch (error: any) {
    console.error("Authentication failed:", error);
    if (
      error.response &&
      error.response.data.message === "Unauthorized domain"
    ) {
      toast.error("Unauthorized domain, need to be a part of Agiledrop team");
    }
  }
};

export default beLogin;
