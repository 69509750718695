import { useRef, useCallback } from "react";
import { VideoItem } from "../types/video-item.type";

const useDragAndDrop = (
  videoQueue: VideoItem[],
  updateVideoQueue: (newQueue: VideoItem[], isEnd?: boolean) => void
) => {
  const dragItem = useRef<HTMLElement | null>(null);
  const dragItemIndex = useRef<number | null>(null);

  const handleDragStart = useCallback(
    (event: React.DragEvent<HTMLSpanElement>, index: number) => {
      dragItem.current = event.currentTarget.closest(".druggable__container");
      dragItemIndex.current = index;

      // Set "ghost" element for dragging
      if (dragItem.current) {
        event.dataTransfer.setDragImage(dragItem.current, 0, 0);
      }
    },
    []
  );

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>, index: number) => {
      if (dragItemIndex.current !== null && dragItemIndex.current !== index) {
        const updatedVideoQueue = [...videoQueue];
        const [removed] = updatedVideoQueue.splice(dragItemIndex.current, 1);
        updatedVideoQueue.splice(index, 0, removed);
        dragItemIndex.current = index;

        (event.target as HTMLElement).classList.add("drag-over");

        updateVideoQueue(updatedVideoQueue, false);
      }
    },
    [videoQueue, updateVideoQueue]
  );

  const handleDragEnd = useCallback(() => {
    if (dragItemIndex.current !== null) {
      const updatedVideoQueue = [...videoQueue];
      const [removed] = updatedVideoQueue.splice(dragItemIndex.current, 1);
      updatedVideoQueue.splice(dragItemIndex.current, 0, removed);

      updateVideoQueue(updatedVideoQueue, true);

      dragItem.current = null;
      dragItemIndex.current = null;
    }
  }, [videoQueue, updateVideoQueue]);

  return { handleDragStart, handleDragEnter, handleDragEnd };
};

export default useDragAndDrop;
