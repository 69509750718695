import {
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ArrowUpIcon } from "@/assets/svg/svg";
import {
  addMessageToVideo,
  removeVideoFromQueue,
} from "@/utils/services/video";
import { VideoItem } from "@/utils/types/video-item.type";
import useDragAndDrop from "@/utils/hooks/useDragAndDrop";
import { useLimitResults } from "@/utils/hooks/useLimitResults";
import { UserContext } from "@/context/UserContext/UserContext";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import Modal from "./Modal/Modal";
import { Socket } from "socket.io-client";
import PlayerListItem from "./PlayerListItem/PlayerListItem";

interface PlayerListProps {
  videoQueue: VideoItem[];
  updateVideoQueue: (newQueue: VideoItem[], isEnd?: boolean) => void;
  isRecentlyPlayed?: boolean;
  onVideoSelect?: (video: VideoItem) => void;
  handleLike?: (video: VideoItem) => void;
  handleDislike?: (video: VideoItem) => void;
  socketRef: MutableRefObject<Socket | null>;
  serverEndpoint: string | undefined;
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void;
  playerRef: any;
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}

const PlayerList: FC<PlayerListProps> = ({
  videoQueue,
  updateVideoQueue,
  isRecentlyPlayed,
  onVideoSelect,
  handleLike,
  handleDislike,
  socketRef,
  serverEndpoint,
  setVideoQueue,
  playerRef,
  scrollableParent,
}) => {
  const userContext = useContext(UserContext)!;
  const { handleDragStart, handleDragEnter, handleDragEnd } = useDragAndDrop(
    videoQueue,
    updateVideoQueue
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageInput, setMessageInput] = useState<string>("");
  const [selectedVideo, setSelectedVideo] = useState<VideoItem>();
  const { displayedItems, isExpanded, handleShowMore, handleBackToTop } =
    useLimitResults(videoQueue);
  const { user } = userContext;

  const openModal = (video: VideoItem) => {
    if (video.message) {
      setMessageInput(video.message);
    }
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(undefined);
    setMessageInput("");
  };

  const sendMessage = (video: VideoItem, messageInput: string) => {
    if (!addMessageToVideo) return;
    addMessageToVideo(
      video,
      user,
      messageInput,
      playerRef,
      serverEndpoint,
      socketRef
    );
    setIsModalOpen(false);
    setSelectedVideo(undefined);
    setMessageInput("");
  };

  return (
    <div>
      <div className="queue">
        {displayedItems.map((video: VideoItem, index: number) => (
          <PlayerListItem
            displayedItems={displayedItems}
            index={index}
            video={video}
            onDragEnter={(e) => handleDragEnter(e, index)}
            onDragEnd={() => handleDragEnd()}
            onDragStart={(e) => handleDragStart(e, index)}
            isRecentlyPlayed={isRecentlyPlayed}
            handleLike={handleLike}
            handleDislike={handleDislike}
            onVideoSelect={onVideoSelect}
            onDeleteClick={() =>
              removeVideoFromQueue(
                video,
                serverEndpoint,
                socketRef,
                setVideoQueue,
                videoQueue,
                isRecentlyPlayed
              )
            }
            onOpenModalClick={() => openModal(video)}
          />
        ))}

        {displayedItems.length < videoQueue.length && (
          <DefaultButton onClick={handleShowMore}>SHOW MORE</DefaultButton>
        )}

        <Modal
          isModalOpen={isModalOpen}
          sendMessage={sendMessage}
          setMessageInput={setMessageInput}
          selectedVideo={selectedVideo}
          messageInput={messageInput}
          closeModal={closeModal}
        />

        {isExpanded && (
          <div className="back-to-top sticky bottom-0 right-0 w-2.5 z-10 ml-auto">
            <span
              className="cursor-pointer inline-block relative"
              style={{ top: "1.25rem" }}
              onClick={() => handleBackToTop(scrollableParent)}
            >
              <ArrowUpIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerList;
