import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserContext } from "@/context/UserContext/UserContext";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";
import LoginGate from "./LoginGate/LoginGate";
import HeaderSkeleton from "./UI/HeaderSkeleton/HeaderSkeleton";

const Header = () => {
  const { mode } = useContext(ThemeContext);
  const { user, userToken } = useContext(UserContext)!;
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const toggleBodyOverflow = (state: boolean) => {
      document.body.style.overflow = state ? "hidden" : "";
    };

    toggleBodyOverflow(opened);

    return () => toggleBodyOverflow(false);
  }, [opened]);

  const headerStyle =
    mode === "dark"
      ? "bg-background-bgDark100 border-accent-transparent"
      : "bg-background-bgLight200 border-accent-gray200 shadow-primary";

  return (
    <>
      {
        <>
          {userToken ? (
            <header
              className={`border-b-[1px] border-solid ${headerStyle} flex justify-between px-8 py-4 items-center relative z-20`}
            >
              {user ? (
                <>
                  <DesktopMenu />
                  <MobileMenu opened={opened} setOpened={setOpened} />
                </>
              ) : (
                <HeaderSkeleton />
              )}
            </header>
          ) : (
            <LoginGate />
          )}
        </>
      }
    </>
  );
};

export default Header;
