import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import DefaultTab from "@/Components/UI/DefaultTab/DefaultTab";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import { UserContext } from "@/context/UserContext/UserContext";
import LikesTabsEnum from "@/utils/enums/likes-tabs.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import FavoriteOptionsTabContent from "./FavoriteOptionsTabContent/FavoriteOptionsTabContent";

interface FavoriteOptionsProps {
  onVideoSelect: (video: VideoItem) => void;
  videosToShow: VideoItem[];
  selectedGroup: GroupsInterface;
  setSelectedGroup: Dispatch<SetStateAction<GroupsInterface>>;
  favoriteUserList: VideoItem[];
  favoriteGroups: GroupsInterface[];
  currentGroup: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  handleCollapseAll: () => void;
}

const FavoriteOptions: FC<FavoriteOptionsProps> = ({
  setSelectedGroup,
  favoriteUserList,
  favoriteGroups,
  currentGroup,
  searchTerm,
  setSearchTerm,
  handleCollapseAll,
}) => {
  const { user } = useContext(UserContext)!;

  const [activeTab, setActiveTab] = useState<LikesTabsEnum>(
    LikesTabsEnum.GROUPS
  );

  const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    const selectedGroup = favoriteGroups!.find(
      (group) => group.value === value
    )!;

    setSelectedGroup(selectedGroup);
    setSearchTerm("");
    handleCollapseAll();
  };

  return (
    <>
      <div className="flex gap-2 text-[0.875rem] justify-between">
        {Object.values(LikesTabsEnum).map((value, id) => {
          return (
            <span key={id} className="basis-3/6">
              <DefaultTab
                onClick={() => setActiveTab(LikesTabsEnum[value])}
                active={activeTab === LikesTabsEnum[value]}
                className="w-full"
              >
                {value.replaceAll("_", " ")}
              </DefaultTab>
            </span>
          );
        })}
      </div>
      <FavoriteOptionsTabContent
        user={user}
        favoriteUserList={favoriteUserList}
        activeTab={activeTab}
        handleGroupChange={handleGroupChange}
        favoriteGroups={favoriteGroups}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        currentGroup={currentGroup}
      />
    </>
  );
};

export default FavoriteOptions;
