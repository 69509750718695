import { useState, useEffect } from "react";

function useDebouncedSearch(initialValue = "", delay = 300) {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(searchTerm);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setIsDebouncing(false);
      setDebouncedValue(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, delay]);

  return { searchTerm, setSearchTerm, debouncedValue, isDebouncing };
}

export default useDebouncedSearch;
