import SpotlightStatsEnum from "@/utils/enums/spotlight-stats-type.enum";
import { UserLeaderItem, UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import axios from "axios";
import { useEffect, useState } from "react";

interface VideoItemDoc {
  _doc: VideoItem;
}

const useFetchLeaderboardData = (dataType: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [topThree, setTopThree] = useState<
    UserSpotlightItem[] | VideoItem[] | null
  >(null);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/leaderboard`
        );
        const responseData = response.data.result[dataType];
        let processedData: any[] = [];

        if (dataType === SpotlightStatsEnum.SONG_MOST_LIKES) {
          processedData = Object.entries(responseData).map(([_, item]) => {
            return (item as VideoItemDoc)._doc;
          });
        } else {
          processedData = Object.entries(responseData).map(([_, item]) => {
            const userItem = item as UserLeaderItem;
            return {
              name: userItem.signName,
              value: userItem.count,
              avatar: userItem.avatar,
            };
          });
        }
        setLoading(false);
        setData(
          processedData.length > 3
            ? processedData.slice(3, processedData.length)
            : null
        );
        setTopThree(Array.from(processedData).slice(0, 3));
      } catch (error) {
        setLoading(false);
        console.log("Error: ", error);
      }
    };

    fetchData();
  }, [dataType]);

  return [loading, data, topThree, setTopThree, setData];
};

export default useFetchLeaderboardData;
