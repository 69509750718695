import { Socket } from "socket.io-client";

const shuffleVideoListHandler = (
  serverEndpoint: string | undefined,
  socketRef: React.MutableRefObject<Socket | null>
) => {
  if (!serverEndpoint) {
    console.error("SERVER_ENDPOINT is not defined");
    return;
  }
  if (socketRef.current) {
    socketRef.current.emit("shuffleVideoList");
  }
};

export default shuffleVideoListHandler;
