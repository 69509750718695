import React from "react";
import { YouTubePlayerRef } from "../types/youtube-player-ref-type";

interface UseCurrentTimeReturn {
  currentTime: string;
  startTimer: () => void;
  stopTimer: () => void;
}

const useCurrentTime = (playerRef: YouTubePlayerRef): UseCurrentTimeReturn => {
  const [currentTime, setCurrentTime] = React.useState("00:00");
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timer | null>(null);

  const formatTime = (timeInSeconds: number): string => {
    if (isNaN(timeInSeconds)) {
      return "00:00";
    }

    const roundTime = Math.round(timeInSeconds);
    const minutes = Math.floor(roundTime / 60);
    const seconds = roundTime % 60;

    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedMinutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const updateCurrentTime = () => {
    if (playerRef.current && playerRef.current.internalPlayer) {
      playerRef.current.internalPlayer.getCurrentTime().then((time) => {
        const formattedTime = formatTime(time);
        setCurrentTime(formattedTime);
      });
    }
  };

  React.useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const startTimer = () => {
    updateCurrentTime();
    const newIntervalId = setInterval(updateCurrentTime, 1000);
    setIntervalId(newIntervalId);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return { currentTime, startTimer, stopTimer };
};

export default useCurrentTime;
