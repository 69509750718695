import { useState, useEffect } from "react";
import axios from "axios";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";

export const useFavoriteGroupsApi = (
  user: string | null
): { data: GroupsInterface[] | null; isLoading: boolean } => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchFavoriteGroups = async () => {
      if (!user) {
        setData(null);
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/favoriteGroups`,
          { params: { email: user }, signal: signal }
        );
        setData(response.data.favoriteGroups);
        setIsLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Failed to fetch favorite list", error);
        }
        setData(null);
      } finally {
      }
    };

    fetchFavoriteGroups();

    return () => {
      abortController.abort();
    };
  }, [user]);

  return { data, isLoading };
};

export default useFavoriteGroupsApi;
