import {
  FC,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket } from "socket.io-client";

export interface IPlayerContext {
  isPlayerVisible: boolean;
  isFavoriteToggled: boolean;
  serverEndpoint: string | undefined;
  socketRef: MutableRefObject<Socket | null>;
  setIsFavoriteToggled: (value: SetStateAction<boolean>) => void;
  setPlayer: () => void;
}

export const PlayerContext = createContext<IPlayerContext | null>(null);

interface PlayerProviderProps {
  children: ReactNode;
}

export const PlayerProvider: FC<PlayerProviderProps> = ({
  children,
}: PlayerProviderProps) => {
  const socketRef = useRef<Socket | null>(null);
  const serverEndpoint: string | undefined =
    process.env.REACT_APP_SERVER_ENDPOINT;

  const [isPlayerVisible, setIsPlayerVisible] = useState(
    JSON.parse(localStorage.getItem("isPlayerVisible") || "false")
  );

  const [isFavoriteToggled, setIsFavoriteToggled] = useState(false);

  useEffect(() => {
    localStorage.setItem("isPlayerVisible", JSON.stringify(isPlayerVisible));
  }, [isPlayerVisible]);

  const setPlayer = () => {
    setIsPlayerVisible((prevState: boolean) => !prevState);
  };

  return (
    <PlayerContext.Provider
      value={{
        isPlayerVisible,
        setPlayer,
        isFavoriteToggled,
        setIsFavoriteToggled,
        serverEndpoint,
        socketRef,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
