import { FC, useContext } from "react";
import PlayerSkeleton from "./PlayerSkeleton/PlayerSkeleton";
import PlaylistSkeleton from "./PlaylistSkeleton/PlaylistSkeleton";
import SidebarSkeleton from "./SidebarSkeleton/SidebarSkeleton";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { SkeletonTheme } from "react-loading-skeleton";

const VideoPlayerSkeleton: FC = () => {
  const { mode } = useContext(ThemeContext);
  const extraClasses =
    mode === "dark"
      ? "bg-background-bgDark100 border-accent-transparent "
      : "border bg-background-bgLight100 border-solid border-accent-gray200 shadow-primary";

  return (
    <SkeletonTheme
      baseColor={`${mode === "dark" ? "#0B2D39" : "#efefef"}`}
      highlightColor={`${mode === "dark" ? "#061d26" : "#F9F9F9"}`}
    >
      <div
        className={`${extraClasses} relative p-6 md:p-8 flex flex-col rounded-md w-full scrollbar-none max-h-[1000px] md:max-h-[750px] xl:max-h-none xl:h-[calc(100vh-140px)]  xl:min-h-[1000px] overflow-y-scroll`}
      >
        <PlayerSkeleton />
        <PlaylistSkeleton mode={mode} />
      </div>
      <div
        className={`${extraClasses} rounded-md p-6 md:p-8 md:pt-0 xl:min-w-[384px] xl:max-w-[384px] 2xl:min-w-[557px] 2xl:w-[557px] scrollbar-none max-h-[1000px] md:max-h-[750px] xl:max-h-none xl:h-[calc(100vh-140px)] xl:min-h-[1000px] overflow-y-scroll`}
      >
        <SidebarSkeleton />
      </div>
    </SkeletonTheme>
  );
};

export default VideoPlayerSkeleton;
