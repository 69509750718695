import { VideoItem } from "@/utils/types/video-item.type";
import { Socket } from "socket.io-client";
import toast from "react-hot-toast";

const handleLike = (
  video: VideoItem,
  user: string | null,
  playerRef: React.MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  serverEndpoint: string | undefined,
  socketRef: React.MutableRefObject<Socket | null>
) => {
  if (user) {
    if (playerRef.current) {
      if (serverEndpoint) {
        if (socketRef.current) {
          socketRef.current.emit("addLike", video, user);
        }
      }
    }
  } else {
    toast.error(`You are not authorized!`);
  }
};

export default handleLike;
