import {
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  NextAudioIcon,
  PauseIcon,
  PlayIcon,
  ShuffleIcon,
  SyncDBIcon,
  VolumeDecreaseIcon,
  VolumeXIcon,
} from "@/assets/svg/svg";
import { UserContext } from "@/context/UserContext/UserContext";
import RangeInput from "@/Components/UI/RangeInput/RangeInput";
import { VideoItem } from "@/utils/types/video-item.type";
import {
  handlePlayPause,
  handleSyncDB,
  handleVolumeChange,
  shuffleVideoListHandler,
} from "@/utils/services/video";
import { Socket } from "socket.io-client";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import ActionBar from "@/Components/UI/ActionBar/ActionBar";

interface PlayerControllerProps {
  volume: number;
  isPlaying: boolean;
  currentVideo: VideoItem;
  handleNext: () => void;
  socketRef: MutableRefObject<Socket | null>;
  serverEndpoint: string | undefined;
  playerRef: any;
  setVolume: (value: SetStateAction<number>) => void;
  setIsFavoriteToggled: (value: SetStateAction<boolean>) => void;
}

const PlayerController: FC<PlayerControllerProps> = ({
  volume,
  isPlaying,
  currentVideo,
  handleNext,
  socketRef,
  serverEndpoint,
  playerRef,
  setVolume,
}) => {
  const tooltipId: TooltipTypeEnum = TooltipTypeEnum.MAIN;
  const { user, role } = useContext(UserContext)!;

  const prevVolume = useRef(volume);

  useEffect(() => {
    if (volume > 0) {
      prevVolume.current = volume;
    }
  }, [volume]);

  const muteVolume = () => {
    handleVolumeChange(0, setVolume, playerRef);
    sessionStorage.setItem("volume", "0");
  };

  const restoreVolume = () => {
    handleVolumeChange(prevVolume.current, setVolume, playerRef);
    sessionStorage.setItem("volume", prevVolume.current.toString());
  };

  const playPauseVideo = () => {
    handlePlayPause(
      playerRef,
      isPlaying,
      volume,
      setVolume,
      serverEndpoint,
      socketRef
    );
  };

  return (
    <div className="flex flex-col gap-5 lg:flex-row lg:gap-12">
      {user && role === "admin" && (
        <div className="flex gap-8 items-center">
          <span className="cursor-pointer" onClick={playPauseVideo}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </span>
          <span className="cursor-pointer" onClick={handleNext}>
            <NextAudioIcon />
          </span>
          <span
            className="cursor-pointer"
            onClick={() => shuffleVideoListHandler(serverEndpoint, socketRef)}
          >
            <ShuffleIcon />
          </span>
        </div>
      )}
      <div className="flex gap-4 items-center">
        {volume === 0 ? (
          <span className="cursor-pointer" onClick={restoreVolume}>
            <VolumeXIcon />
          </span>
        ) : (
          <span className="cursor-pointer" onClick={muteVolume}>
            <VolumeDecreaseIcon />
          </span>
        )}
        <RangeInput
          volume={volume}
          playerRef={playerRef}
          setVolume={setVolume}
        />
        <span className="cursor-pointer" onClick={playPauseVideo}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </span>
        <span
          className="cursor-pointer"
          onClick={() =>
            handleSyncDB(playerRef, serverEndpoint, socketRef, isPlaying)
          }
        >
          <SyncDBIcon />
        </span>
        <ActionBar
          video={currentVideo}
          tooltipId={tooltipId}
          showAddToPlaylistIcon={false}
          showLikesIcon={user !== null}
        />
      </div>
    </div>
  );
};

export default PlayerController;
