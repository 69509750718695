import { FC, useContext } from "react";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import { DislikeIcon, LikeIcon } from "@/assets/svg/svg";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import { UserContext } from "@/context/UserContext/UserContext";

interface LikesDislikesControllerProps {
  currentVideo: VideoItem;
  handleLike?: (video: VideoItem) => void;
  handleDislike?: (video: VideoItem) => void;
}

const LikesDislikesController: FC<LikesDislikesControllerProps> = ({
  currentVideo,
  handleLike,
  handleDislike,
}) => {
  const { mode } = useContext(ThemeContext);
  const { user } = useContext(UserContext)!;

  return (
    <>
      <div
        className={`${
          mode === "dark" ? "bg-neutral-white" : "bg-accent-gray400"
        } hidden md:block w-2 h-2  rounded-full`}
      ></div>
      <div className="flex gap-3 items-center">
        <div className="flex items-center">
          <span
            onClick={() => handleLike?.(currentVideo)}
            className={`hover:scale-125 transition-transform duration-300`}
          >
            <LikeIcon
              isLiked={currentVideo?.userReactions?.[user!] === "like"}
            />
          </span>
          <Paragraph type={ParagraphTypeEnum.p1_Small} className="video__added">
            {currentVideo.likes || 0}
          </Paragraph>
        </div>
        <div className="flex items-center">
          <span
            onClick={() => handleDislike?.(currentVideo)}
            className={`hover:scale-125 transition-transform duration-300`}
          >
            <DislikeIcon
              isDisliked={currentVideo?.userReactions?.[user!] === "dislike"}
            />
          </span>

          <Paragraph type={ParagraphTypeEnum.p1_Small} className="video__added">
            {currentVideo.dislikes || 0}
          </Paragraph>
        </div>
      </div>
    </>
  );
};

export default LikesDislikesController;
