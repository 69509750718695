import { MutableRefObject, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import handleVolumeChange from "./handleVolumeChange";

const handlePlayPause = (
  playerRef: MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  isPlaying: boolean,
  volume: number,
  setVolume: (value: SetStateAction<number>) => void,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>
) => {
  if (playerRef.current) {
    if (isPlaying) {
      sessionStorage.setItem("isPaused", "true");
      sessionStorage.setItem("volume", volume.toString());
      setVolume(0);
      playerRef.current.internalPlayer.pauseVideo();
    } else {
      if (sessionStorage.getItem("isPaused") === "true") {
        sessionStorage.setItem("isPaused", "false");
        const volumeFromStorage = sessionStorage.getItem("volume");
        if (volumeFromStorage) {
          handleVolumeChange(parseInt(volumeFromStorage), setVolume, playerRef);
        }
      }
      playerRef.current.internalPlayer.playVideo();
      // Get current time from server
      if (serverEndpoint) {
        if (socketRef.current) {
          socketRef.current.emit("requestCurrentTime");
        }
      }

      // Send current play state to server
      if (serverEndpoint) {
        if (socketRef.current) {
          socketRef.current.emit("togglePlayPause", !isPlaying);
          socketRef.current.on("currentVideoTime", (time) => {
            if (playerRef.current) {
              (playerRef.current.internalPlayer as any).seekTo(time / 1000);
            }
          });
        }
      }
    }
  }
};

export default handlePlayPause;
