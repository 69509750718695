import { Dispatch, FC, SetStateAction } from "react";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import { VideoItem } from "@/utils/types/video-item.type";
import LikesTabsEnum from "@/utils/enums/likes-tabs.enum";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import TransformedSelectDropdown from "./TransformedSelectDropdown/TransformedSelectDropdown";
import FavoriteSettings from "./FavoriteSettings/FavoriteSettings";

interface FavoriteOptionsTabContentProps {
  user: string | null;
  favoriteUserList: VideoItem[];
  activeTab: LikesTabsEnum;
  handleGroupChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  favoriteGroups: GroupsInterface[];
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  currentGroup: string;
}

const FavoriteOptionsTabContent: FC<FavoriteOptionsTabContentProps> = ({
  user,
  favoriteUserList,
  activeTab,
  handleGroupChange,
  favoriteGroups,
  searchTerm,
  setSearchTerm,
  currentGroup,
}) => {
  return (
    <div className="flex items-center min-h-[5rem]">
      {user && favoriteUserList && activeTab === LikesTabsEnum.GROUPS && (
        <TransformedSelectDropdown
          handleChange={handleGroupChange}
          favoriteGroups={favoriteGroups}
          currentGroup={currentGroup}
        >
          <FavoriteSettings
            favoriteGroups={favoriteGroups}
            className="absolute top-0 right-0"
          />
        </TransformedSelectDropdown>
      )}

      {activeTab === LikesTabsEnum.SEARCH && (
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SearchInput
            labelText="Search favorites"
            searchTerm={searchTerm}
            handleChange={(e) => setSearchTerm(e.target.value)}
            placeholder={"Search favorites"}
          />
        </form>
      )}
    </div>
  );
};

export default FavoriteOptionsTabContent;
