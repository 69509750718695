import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import UserSettingsTabTypeEnum from "@/utils/enums/user-settings-tab-type.enum";
import React, { FC, useContext } from "react";

interface UserSettingsTabProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tab: {
    id: UserSettingsTabTypeEnum;
    title: string;
    icon: React.ReactElement;
  };
  activeTab: UserSettingsTabTypeEnum;
  isLastTab: boolean;
  hoveredTab: UserSettingsTabTypeEnum | null;
}

const UserSettingsTab: FC<UserSettingsTabProps> = ({
  tab,
  activeTab,
  isLastTab,
  hoveredTab,
  ...props
}) => {
  const { mode } = useContext(ThemeContext);
  return (
    <button
      className={`border-r border-b border-accent-transparent
                ${
                  mode === "dark"
                    ? "text-neutral-white "
                    : "text-primary-blackPetrol"
                } 

                ${
                  activeTab !== tab.id &&
                  mode === "dark" &&
                  "border-b bg-background-bgDark200"
                }
                ${
                  activeTab === tab.id &&
                  mode === "dark" &&
                  "border-b bg-background-bgDark100"
                }

                ${
                  activeTab !== tab.id &&
                  mode === "light" &&
                  "border-b border-b-accent-gray100 bg-background-bgLight200"
                } 
                ${
                  activeTab === tab.id &&
                  mode === "light" &&
                  "bg-background-bgLight100 border-b border-b-background-bgLight100"
                } 
                
                ${!isLastTab && mode === "light" && "border-r-accent-gray100"} 

                px-2 py-4 w-full font-nunitoSans font-bold hover:text-secondary-lightPetrol flex flex-col items-center justify-center gap-2 min-w-[96px]`}
      {...props}
    >
      {React.cloneElement(tab.icon, { hover: hoveredTab === tab.id })}
      {tab.title}
    </button>
  );
};

export default UserSettingsTab;
