import { useMemo } from "react";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import { VideoItem } from "@/utils/types/video-item.type";

const useVideosWithoutGroup = (
  groups: GroupsInterface[],
  favoriteUserList: VideoItem[]
) => {
  const videosInCustomGroups = groups
    .filter((group) => group.id !== 1 && group.id !== 2)
    .flatMap((group) => group.videos);

  const customGroupVideoIds = new Set(
    videosInCustomGroups.map((video) => video.id)
  );

  const videosWithoutGroup = useMemo(() => {
    return favoriteUserList.filter(
      (video) => !customGroupVideoIds.has(video.id)
    );
  }, [groups, favoriteUserList]);

  return videosWithoutGroup;
};

export default useVideosWithoutGroup;
