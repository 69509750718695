import toast from "react-hot-toast";
import { VideoItem } from "../types/video-item.type";

export const shareVideo = (currentVideo: VideoItem | null) => {
  const url = `${process.env.REACT_APP_SERVER_ENDPOINT}/slack/share`;

  const data = { currentVideo: currentVideo };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        toast.success("Video shared successfully");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
