import { FC } from "react";
import { FakeSelectProps } from "@/utils/types/fake-select.type";

const FakeSelectOptionList: FC<FakeSelectProps> = ({
  currentTheme,
  options,
  handleOptionChange,
  name,
}) => {
  return (
    <div className="relative z-20">
      <ul
        className={`border ${currentTheme.activeDropdown} rounded-b-md border-t-0 py-2 absolute w-full focus:${currentTheme.labelText}`}
      >
        {options!.map((item) => (
          <li key={item.id} className="relative">
            <input
              type="radio"
              value={item.value}
              onChange={handleOptionChange}
              id={item.id.toString()}
              name={name}
              className="block absolute w-full h-full rounded-none opacity-0 cursor-pointer z-10"
            />
            <label
              htmlFor={item.value}
              className={`${currentTheme.text} text-[14px] font-normal leading-5 block py-2 ps-3 pe-4`}
            >
              {item.value}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FakeSelectOptionList;
