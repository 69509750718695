import React, { FC, useState } from "react";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";

interface CreateGroupProps {
  onCreateGroup: (groupName: string) => void;
}

const CreateGroup: FC<CreateGroupProps> = ({ onCreateGroup }) => {
  const [newGroupName, setNewGroupName] = useState("");

  const handleCreateGroup = () => {
    if (newGroupName.trim()) {
      onCreateGroup(newGroupName);
      setNewGroupName("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(event.target.value);
  };

  return (
    <div className="flex flex-col gap-2 mb-6">
      <SearchInput
        searchTerm={newGroupName}
        handleChange={handleChange}
        placeholder="New Group Name..."
        maxLength={30}
      />
      <DefaultButton onClick={handleCreateGroup}>CREATE GROUP</DefaultButton>
    </div>
  );
};

export default CreateGroup;
