import React, { createContext, useReducer, useContext, useEffect } from "react";
import { UserContext } from "../UserContext/UserContext";
import useFavoriteGroupsApi from "./utils/hooks/useFavoriteGroupsApi";
import { FavoriteGroupsActionEnum } from "@/utils/enums/favorite-groups-action.enum";
import {
  FavoriteGroupsAction,
  FavoriteGroupsContextProps,
  FavoriteGroupsProviderProps,
  FavoriteGroupsState,
} from "./utils/types/favorite-groups.type";

const initialState: FavoriteGroupsState = {
  groups: [],
  isLoading: true,
};

export const FavoriteGroupsContext = createContext<
  FavoriteGroupsContextProps | undefined
>(undefined);

const favoriteGroupsReducer = (
  state: FavoriteGroupsState,
  action: FavoriteGroupsAction
): FavoriteGroupsState => {
  switch (action.type) {
    case FavoriteGroupsActionEnum.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case FavoriteGroupsActionEnum.SET_GROUPS:
      return { ...state, groups: action.payload };
    case FavoriteGroupsActionEnum.ADD_GROUP:
      return { ...state, groups: [...state.groups, action.payload] };
    case FavoriteGroupsActionEnum.REMOVE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== action.payload.id),
      };
    case FavoriteGroupsActionEnum.UPDATE_GROUP:
      return {
        ...state,
        groups: action.payload,
      };
    default:
      return state;
  }
};

export const FavoriteGroupsProvider: React.FC<FavoriteGroupsProviderProps> = ({
  children,
}) => {
  const { user } = useContext(UserContext)!;
  const { data: favoriteGroupsData, isLoading } = useFavoriteGroupsApi(user);
  const [state, dispatch] = useReducer(favoriteGroupsReducer, initialState);

  useEffect(() => {
    if (!isLoading && favoriteGroupsData) {
      dispatch({
        type: FavoriteGroupsActionEnum.SET_GROUPS,
        payload: favoriteGroupsData,
      });
      dispatch({ type: FavoriteGroupsActionEnum.SET_LOADING, payload: false });
    }
  }, [favoriteGroupsData, isLoading]);

  return (
    <FavoriteGroupsContext.Provider value={{ state, dispatch }}>
      {children}
    </FavoriteGroupsContext.Provider>
  );
};
