import { DragEventHandler, FC, useContext } from "react";
import {
  AddIcon,
  DeleteIcon,
  DragAndDropIcon,
  MessageEditIcon,
} from "@/assets/svg/svg";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserContext } from "@/context/UserContext/UserContext";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import LikesDislikesController from "@/Components/VideoPlayer/Queue/LikesDislikesController/LikesDislikesController";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import Heading from "@/Components/UI/Heading/Heading";

interface PlayerListItemProps {
  displayedItems: VideoItem[];
  index: number;
  video: VideoItem;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragEnd: () => void;
  onDragStart: DragEventHandler<HTMLDivElement>;
  isRecentlyPlayed: boolean | undefined;
  handleLike: ((video: VideoItem) => void) | undefined;
  handleDislike: ((video: VideoItem) => void) | undefined;
  onVideoSelect: ((video: VideoItem) => void) | undefined;
  onDeleteClick: () => void;
  onOpenModalClick: () => void;
}

const PlayerListItem: FC<PlayerListItemProps> = ({
  displayedItems,
  index,
  video,
  onDragEnter,
  onDragEnd,
  onDragStart,
  isRecentlyPlayed,
  handleLike,
  handleDislike,
  onVideoSelect,
  onDeleteClick,
  onOpenModalClick,
}) => {
  const { user, role, name } = useContext(UserContext)!;

  const { mode } = useContext(ThemeContext);
  return (
    <div
      className={`druggable__container transition-none flex flex-col md:flex-row gap-4 md:items-center md:justify-between md:gap-8 py-6 ${
        displayedItems.length - 1 !== index
          ? `border-b ${
              mode === "dark"
                ? "border-accent-gray300"
                : "border-accent-gray100"
            }`
          : ""
      }`}
      key={video.id}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          {user && role === "admin" && !isRecentlyPlayed && (
            <span
              draggable
              onDragStart={onDragStart}
              style={{ cursor: "grab" }}
            >
              <DragAndDropIcon />
            </span>
          )}
          {video.title && (
            <div className="relative">
              <Heading type={HeadingTypeEnum.h2_Small}>{video.title}</Heading>
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-1 md:gap-3 md:items-center">
          <Paragraph
            type={ParagraphTypeEnum.p1_Small}
            className="video__container_duration"
          >
            Length: {video.duration}
          </Paragraph>
          <LikesDislikesController
            currentVideo={video}
            handleLike={handleLike}
            handleDislike={handleDislike}
          />
          <div
            className={`${
              mode === "dark" ? "bg-neutral-white" : "bg-accent-gray400"
            } hidden md:block w-2 h-2  rounded-full`}
          ></div>
          <Paragraph type={ParagraphTypeEnum.p1_Small} className="video__added">
            Added by {video.added ? video.added : "Anonymous"}
          </Paragraph>
        </div>
      </div>
      <div className="flex gap-4">
        <ActionBar
          video={video}
          tooltipId={TooltipTypeEnum.PLAYERLIST}
          showAddToPlaylistIcon={false}
        >
          {user &&
            (role === "admin" || video.added === name) &&
            (isRecentlyPlayed || index !== 0) && (
              <span
                className="cursor-pointer relative top-1"
                onClick={onDeleteClick}
              >
                <DeleteIcon />
              </span>
            )}
          {user && video.added === name && !isRecentlyPlayed && (
            <span
              className="cursor-pointer relative"
              onClick={onOpenModalClick}
            >
              <MessageEditIcon />
            </span>
          )}
          {user && onVideoSelect && isRecentlyPlayed && (
            <span
              className="cursor-pointer relative top-[2px]"
              onClick={() =>
                onVideoSelect({
                  id: video.id,
                  title: video.title,
                  duration: video.duration,
                  ...(user ? { added: name } : {}),
                  likes: video.likes,
                  dislikes: video.dislikes,
                  userReactions: video.userReactions,
                })
              }
            >
              <AddIcon />
            </span>
          )}
        </ActionBar>
      </div>
    </div>
  );
};

export default PlayerListItem;
