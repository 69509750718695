import { IFavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { IUserContext } from "@/context/UserContext/UserContext";
import axios from "axios";
import toast from "react-hot-toast";

export const getUserData = async (
  userContext: IUserContext | null,
  favoriteContext: IFavoriteContext | null
) => {
  try {
    const authToken = localStorage.getItem("jwt");
    if (authToken) {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      userContext?.setUser(response.data.user.email);
      userContext?.setPicture(response.data.user.picture);
      userContext?.setRole(response.data.user.role);
      favoriteContext?.setFavoriteUserList(response.data.user.favoriteVideos);
      userContext?.setName(response.data.user.signName);
    }
  } catch (error: any) {
    // clear locale storage if token is invalid
    localStorage.removeItem("jwt");
    console.log("Error getting user data:", error);
    if (error?.response?.data?.message === "Invalid or expired token") {
      toast.error(
        "Invalid or expired token, please log in again to refresh your token"
      );
    }
  }
};
