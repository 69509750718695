import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
} from "react";
import FavoritePlayListModal from "./FavoritePlayListModal/FavoritePlayListModal";
import VideoList from "./VideoList/VideoList";
import { UserContext } from "@/context/UserContext/UserContext";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";

interface FavoriteContentProps {
  displayedItems: VideoItem[];
  videosToShow: VideoItem[];
  selectedVideo: VideoItem | null;
  setSelectedVideo: Dispatch<SetStateAction<VideoItem | null>>;
  handleShowMore: () => void;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  handleBackToTop: (
    ref: MutableRefObject<HTMLDivElement | null> | null,
    behavior?: ScrollBehavior,
    timeout?: number
  ) => void;
  scrollableParent: MutableRefObject<HTMLDivElement | null> | undefined;
}

const FavoriteContent: FC<FavoriteContentProps> = ({
  displayedItems,
  videosToShow,
  selectedVideo,
  setSelectedVideo,
  handleShowMore,
  isModalOpen,
  setIsModalOpen,
  isExpanded,
  handleBackToTop,
  scrollableParent,
}) => {
  const { user } = useContext(UserContext)!;
  const { favoriteUserList } = useContext(FavoriteContext)!;
  const { state } = useFavoriteGroupsContext();
  const { groups } = state;

  const favoriteGroups = groups;

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = (video: VideoItem) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  return (
    <>
      <VideoList
        videos={displayedItems}
        openModal={openModal}
        handleShowMore={handleShowMore}
        isExpanded={isExpanded}
        scrollableParent={scrollableParent}
        videosToShow={videosToShow}
        favoriteGroups={favoriteGroups}
        handleBackToTop={handleBackToTop}
        closeModal={closeModal}
      />

      <FavoritePlayListModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        video={selectedVideo}
        favoriteGroups={favoriteGroups}
      />

      {!user && (
        <div>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            You must log in
          </Paragraph>
        </div>
      )}
      {user && favoriteUserList && favoriteUserList.length === 0 && (
        <div>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            No favorite videos
          </Paragraph>
        </div>
      )}
    </>
  );
};

export default FavoriteContent;
