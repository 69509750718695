import { CloseIcon } from '@/assets/svg/svg';
import { useToastContext } from "@/context/ToastContext/ToastContext";
import parseDurationToMilliseconds from "@/utils/helpers/parseDurationToMilliseconds";
import { VideoItem } from "@/utils/types/video-item.type";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import linkify from "@/utils/helpers/linkify";

export const useMessageToast = (currentVideo: VideoItem | null  ) => {
    const { setToastId } = useToastContext()!;

    const [shownToastForVideo, setShownToastForVideo] = useState<null | string>(
        null
      );

    useEffect(() => {
        if (
          currentVideo &&
          currentVideo.message &&
          shownToastForVideo !== currentVideo.id
        ) {
          const duration = parseDurationToMilliseconds(currentVideo.duration!);
          setShownToastForVideo(currentVideo.id);
          toast(
            (t) => {
              setToastId(t.id);
              return (
                <div className="flex gap-4">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkify(currentVideo.message),
                    }}
                  />
                  <button
                    className="flex items-center"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <CloseIcon forcedColor="#000" />
                  </button>
                </div>
              );
            },
            {
              duration: duration,
              position: "top-center",
              id: "message_id",
            }
          );
        }
      }, [currentVideo]);

      return  {};
}