/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from "react";
import { YouTubeProps } from "react-youtube";
import { Toaster } from "react-hot-toast";
import { UserContext } from "@/context/UserContext/UserContext";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import {
  toggleFavorite,
  updateVideoQueueByDragAndDrop,
  onVideoSelect,
  handleNext,
  handleLike,
  handleDislike,
  handlePlayerState,
} from "@/utils/services/video";
import initializeSocketConnection from "@/utils/api/websockets";
import getFavoriteListApi from "@/utils/api/getFavoriteListApi";
import { VideoItem } from "@/utils/types/video-item.type";
import { User } from "@/utils/types/user.type";
import VideoPlayerSkeleton from "./VideoPlayerSkeleton/VideoPlayerSkeleton";
import Queue from "./Queue/Queue";
import UserSetting from "./UserSetting/UserSetting";

const VideoPlayer = () => {
  const [videoQueue, setVideoQueue] = useState<VideoItem[]>([]);
  const [recentlyPlayedQueue, setRecentlyPlayedQueue] = useState<VideoItem[]>(
    []
  );
  const [currentVideo, setCurrentVideo] = useState<VideoItem | null>(null);
  const [endTriggered, setEndTriggered] = useState(false);
  const [volume, setVolume] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [usersList, setUsersList] = useState<User[]>([]);

  const { setFavoriteUserList } = useContext(FavoriteContext)!;
  const { socketRef, serverEndpoint, isFavoriteToggled, setIsFavoriteToggled } =
    useContext(PlayerContext)!;

  const { user, userToken } = useContext(UserContext)!;

  const playerRef = useRef<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>(null);

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      disablekb: 1,
      controls: 0,
    },
  };

  // useEffect for socket connection
  useEffect(() => {
    if (!serverEndpoint) {
      console.error("SERVER_ENDPOINT is not defined");
      return;
    }
    initializeSocketConnection(
      serverEndpoint,
      socketRef,
      setVideoQueue,
      currentVideo,
      setCurrentVideo,
      setUsersList,
      setRecentlyPlayedQueue,
      setIsPlaying,
      playerRef,
      setUserCount
    );

    socketRef.current!.on("connect", () => {
      const userData = localStorage.getItem("email");
      socketRef.current!.emit("userData", userData);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        sessionStorage.clear();
      }
    };
  }, []);

  // useEffect for user login
  useEffect(() => {
    if (user) {
      localStorage.setItem("email", user);
    }

    if (!serverEndpoint) {
      console.error("SERVER_ENDPOINT is not defined");
      return;
    }

    if (socketRef.current && user) {
      socketRef.current.emit("userLogin", user);
    }
  }, [user]);

  // useEffect for videoQueue
  useEffect(() => {
    let videoStarted = false;
    videoStarted = handlePlayerState(
      playerRef,
      endTriggered,
      setEndTriggered,
      currentVideo,
      serverEndpoint,
      socketRef
    );

    if (currentVideo && !videoStarted) {
      setEndTriggered(false);
    }
  }, [currentVideo]);

  // useEffect for favorite videos
  useEffect(() => {
    const fetchFavorites = async () => {
      const favoriteVideos = await getFavoriteListApi(user);
      if (favoriteVideos) {
        setFavoriteUserList(favoriteVideos);
      }
    };

    fetchFavorites();
  }, [user, isFavoriteToggled]);

  return (
    <div className="flex flex-col xl:flex-row justify-center p-6 sm:p-8 gap-6 sm:gap-8 scrollbar-none">
      {userToken &&
        (user && currentVideo ? (
          <>
            <Queue
              currentVideo={currentVideo}
              opts={opts}
              playerRef={playerRef}
              serverEndpoint={serverEndpoint}
              socketRef={socketRef}
              endTriggered={endTriggered}
              setEndTriggered={setEndTriggered}
              setVolume={setVolume}
              isPlaying={isPlaying}
              volume={volume}
              videoQueue={videoQueue}
              setVideoQueue={setVideoQueue}
              setIsFavoriteToggled={setIsFavoriteToggled}
              userCount={userCount}
              updateVideoQueue={(newQueue, isEnd) =>
                updateVideoQueueByDragAndDrop(
                  newQueue,
                  isEnd,
                  socketRef,
                  setVideoQueue,
                  videoQueue
                )
              }
              recentlyPlayedQueue={recentlyPlayedQueue}
              handleNext={() => handleNext(socketRef)}
              skipBrokenVideo={() =>
                handleNext(socketRef, "skipBrokenVideo", user)
              }
              setIsPlaying={setIsPlaying}
              handleLike={(video) =>
                handleLike(video, user, playerRef, serverEndpoint, socketRef)
              }
              handleDislike={(video) =>
                handleDislike(video, user, playerRef, serverEndpoint, socketRef)
              }
              onVideoSelect={(video) =>
                onVideoSelect(video, serverEndpoint, socketRef)
              }
            />
            <UserSetting
              onVideoSelect={(video) =>
                onVideoSelect(video, serverEndpoint, socketRef)
              }
              toggleFavorite={(video) =>
                toggleFavorite(video, user, setIsFavoriteToggled)
              }
              isFavoriteToggled={isFavoriteToggled}
              usersList={usersList}
              userCount={userCount}
              setIsFavoriteToggled={setIsFavoriteToggled}
            />
          </>
        ) : (
          <VideoPlayerSkeleton />
        ))}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default VideoPlayer;
