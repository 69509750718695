import { FC, useContext } from "react";
import toast from "react-hot-toast";
import {
  AddFavoriteToFolderIcon,
  FolderFilledIcon,
  StarEmptyIcon,
  StarSolidIcon,
} from "@/assets/svg/svg";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import { toggleFavorite } from "@/utils/services/video";
import { FavoriteGroupsActionEnum } from "@/utils/enums/favorite-groups-action.enum";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import cropText from "@/utils/helpers/cropText";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import { UserContext } from "@/context/UserContext/UserContext";
import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import Heading from "@/Components/UI/Heading/Heading";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import Tag from "@/Components/UI/Tag/Tag";
import removeVideoFromFavoriteGroupApi from "../../../utils/api/removeVideoFromFavoriteGroupApi";

interface VideoListContentProps {
  video: VideoItem;
  openModal: (video: VideoItem) => void;
  closeModal: (() => void) | undefined;
  videoGroups: Map<string, string>;
}

const VideoListContent: FC<VideoListContentProps> = ({
  video,
  openModal,
  closeModal,
  videoGroups,
}) => {
  const { user } = useContext(UserContext)!;
  const { favoriteUserList } = useContext(FavoriteContext)!;
  const { state, dispatch } = useFavoriteGroupsContext();
  const { groups } = state;
  const { setIsFavoriteToggled } = useContext(PlayerContext)!;
  const favoriteGroups = groups;

  const isVideoFavorite = (video: VideoItem) => {
    return favoriteUserList.some(
      (favoriteVideo) => favoriteVideo.id === video.id
    );
  };

  const removeVideoFromGroup = async (video: VideoItem) => {
    let groupid = 0;
    for (const group of favoriteGroups!) {
      if (group.id === 1 && group.label === "All Videos") continue;

      const videoFound = group.videos.some((v) => v.id === video.id);
      if (videoFound) {
        groupid = group.id;
      }
    }

    const response = await removeVideoFromFavoriteGroupApi(
      user,
      groupid,
      video!
    );

    if (response?.message) {
      toast.success(response.message);
      dispatch({
        type: FavoriteGroupsActionEnum.UPDATE_GROUP,
        payload: response.updatedGroup,
      });
    } else {
      toast.error("Failed to update favorites group.");
    }
    toggleFavorite(video, user, setIsFavoriteToggled);
    closeModal!();
  };

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row-reverse gap-4 md:items-center md:justify-between md:gap-8">
        <div className="flex gap-3 items-center">
          <ActionBar
            video={video}
            tooltipId={TooltipTypeEnum.FAVORITE}
            showLikesIcon={false}
          >
            <span
              className="cursor-pointer"
              onClick={() => removeVideoFromGroup(video)}
            >
              {isVideoFavorite(video) ? (
                <StarSolidIcon id={`${video.id}${TooltipTypeEnum.FAVORITE}`} />
              ) : (
                <StarEmptyIcon id={`${video.id}${TooltipTypeEnum.FAVORITE}`} />
              )}
            </span>
            <span onClick={() => openModal(video)}>
              <AddFavoriteToFolderIcon />
            </span>
          </ActionBar>
        </div>

        <div>
          <Heading type={HeadingTypeEnum.h3_Default}>
            {cropText(video.title)}
          </Heading>
          <div className="flex flex-col md:flex-row gap-1 md:gap-3 md:items-center">
            <Paragraph
              type={ParagraphTypeEnum.p2_Default}
              className="result-duration"
            >
              Length: {video.duration}
            </Paragraph>
          </div>
        </div>
      </div>
      {videoGroups.get(video.id) && (
        <div className="groups flex flex-wrap justify-end gap-1 mb-[-0.5rem] mt-[0.25rem]">
          {videoGroups.get(video.id) !== "Without Group" && (
            <Tag
              icon={<FolderFilledIcon />}
              label={videoGroups.get(video.id)!}
              className="bottom-[5px] right-0 ml-0"
            />
          )}
        </div>
      )}
    </>
  );
};

export default VideoListContent;
