import React, { FC, useContext, useState } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import FakeSelect from "./UI/FakeSelect/FakeSelect";

interface SelectDropdownProps {
  options: { id: string | number; value: string }[];
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  labelText: string;
  className?: string;
  defaultSelected?: string;
}

const SelectDropdown: FC<SelectDropdownProps> = ({
  options,
  handleChange,
  name,
  labelText,
  className = "mt-6 mb-8 md:my-8",
  defaultSelected,
  ...props
}) => {
  const { mode } = useContext(ThemeContext);
  const [selected, setSelected] = useState<string>(
    defaultSelected || options[0].value
  );
  const [dropdownExpanded, setDropdownExpanded] = useState<boolean>(false);

  const themeStyles = {
    dark: {
      bg: "background-bgDark100",
      border: "accent-gray300",
      activeBorder: "focus:border-accent-gray100",
      activeDropdown: "border-accent-gray100 bg-background-bgDark100",
      text: "text-accent-gray200",
      labelText: "text-neutral-white",
    },
    light: {
      bg: "neutral-white",
      border: "accent-gray100",
      activeBorder: "focus:border-accent-gray300",
      activeDropdown: "border-accent-gray300 bg-neutral-white",
      text: "text-accent-gray400",
      labelText: "text-primary-blackPetrol",
    },
  };

  const currentTheme = themeStyles[mode];

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const simulatedEvent = {
      target: { name: name, id: e.target.id, value: e.target.value },
    } as React.ChangeEvent<HTMLSelectElement>;
    handleChange(simulatedEvent);
    setSelected(e.target.value);
    setDropdownExpanded(false);
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={`${currentTheme.labelText} font-semibold text-[12px]`}
      >
        {labelText.toUpperCase()}
      </label>
      <select
        name={name}
        id={name}
        onChange={handleChange}
        value={selected}
        className={"hidden"}
        {...props}
      >
        {options.map((item) => (
          <option key={item.id} value={item.value}>
            {item.value}
          </option>
        ))}
      </select>
      <FakeSelect
        name={name}
        selected={selected}
        currentTheme={currentTheme}
        handleClick={() => setDropdownExpanded(!dropdownExpanded)}
        handleOptionChange={handleOptionChange}
        dropdownExpanded={dropdownExpanded}
        options={options}
      />
    </div>
  );
};

export default SelectDropdown;
