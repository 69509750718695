function durationInMinutes(isoDuration: string | null): number {
  if (!isoDuration || isoDuration === "P0D") return 0;

  const match = isoDuration.match(/P(\d+D)?T?(\d+H)?(\d+M)?(\d+S)?/);

  // Check if 'match' is not null
  if (match) {
    const days = (parseInt(match[1]) || 0) * 24 * 60; // Convert days to minutes
    const hours = (parseInt(match[2]) || 0) * 60; // Convert hours to minutes
    const minutes = parseInt(match[3]) || 0;
    const seconds = (parseInt(match[4]) || 0) / 60; // Convert seconds to fraction of a minute

    const totalMinutes = days + hours + minutes + seconds;

    return totalMinutes;
  }

  return 0; // Return 0 if 'match' is null
}

export default durationInMinutes;
