import { useContext } from "react";
import audioIcon from "@/assets/svg/audio.svg";
import videoIcon from "@/assets/svg/video.svg";
import { PlayerContext } from "@/context/PlayerContext/PlayerContext";

const PlayerToggle = () => {
  const { isPlayerVisible, setPlayer } = useContext(PlayerContext)!;

  const handleToggle = () => {
    setPlayer();
  };

  const sliderPosition = isPlayerVisible ? { right: "46px" } : { right: "2px" };

  return (
    <button
      className="bg-button-btnDark relative cursor-pointer flex justify-between items-center gap-4 h-8 py-[2px] pl-2 pr-3 rounded-[50px]"
      onClick={handleToggle}
      aria-label="Toggle video player visibility"
    >
      <img className="w-5 h-5" src={audioIcon} alt="Audio Player" />
      <img
        className="w-5 h-5 relative left-1"
        src={videoIcon}
        alt="Video Player"
      />
      <div
        className="w-7 h-7 rounded-full bg-neutral-white absolute transition-all duration-300"
        style={sliderPosition}
      />
    </button>
  );
};

export default PlayerToggle;
