import { useEffect } from "react";
import { VideoItem } from "@/utils/types/video-item.type";
import { UserFromDB } from "./useHistoryData";

const useFilteredVideos = (
  selectedUserId: string | undefined,
  debouncedValue: string,
  users: UserFromDB[],
  setVideos: React.Dispatch<React.SetStateAction<VideoItem[]>>
) => {
  useEffect(() => {
    if (selectedUserId && users) {
      const selectedUser = users.find((user) => user.id === selectedUserId);
      if (selectedUser) {
        const filteredVideos = selectedUser.videos.filter((video: VideoItem) =>
          video.title.toLowerCase().includes(debouncedValue.toLowerCase())
        );
        setVideos(filteredVideos);
      }
    }
  }, [selectedUserId, debouncedValue, users, setVideos]);
};

export default useFilteredVideos;
