import { GoogleOAuthProvider } from "@react-oauth/google";
import VideoPlayer from "./Components/VideoPlayer/VideoPlayer";
import Header from "./Components/Header/Header";
import { UserProvider } from "./context/UserContext/UserContext";
import { ThemeProvider } from "./context/ThemeContext/ThemeContext";
import { FavoriteProvider } from "./context/FavoriteContext/FavoriteContext";
import { PlayerProvider } from "./context/PlayerContext/PlayerContext";
import { ToastProvider } from "./context/ToastContext/ToastContext";
import { FavoriteGroupsProvider } from "./context/FavoriteGroupsContext/FavoriteGroupsContext";

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <UserProvider>
          <FavoriteGroupsProvider>
            <FavoriteProvider>
              <PlayerProvider>
                <ThemeProvider>
                  <ToastProvider>
                    <Header />
                    <VideoPlayer />
                  </ToastProvider>
                </ThemeProvider>
              </PlayerProvider>
            </FavoriteProvider>
          </FavoriteGroupsProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
