import { MutableRefObject, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import onEnd from "./onEnd";
import { VideoItem } from "@/utils/types/video-item.type";

const handlePlayerState = (
  playerRef: MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  endTriggered: boolean,
  setEndTriggered: (value: SetStateAction<boolean>) => void,
  currentVideo: VideoItem | null,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>
) => {
  let videoStarted = false;
  if (playerRef.current && playerRef.current.internalPlayer) {
    playerRef.current.internalPlayer
      .getPlayerState()
      .then((result) => {
        if (result === 3) {
          playerRef.current!.internalPlayer.playVideo();
        }
        if (result === 1) {
          videoStarted = true;
        }
        if ((result === -1 || result === 0) && videoStarted) {
          onEnd(
            endTriggered,
            setEndTriggered,
            currentVideo,
            serverEndpoint,
            socketRef
          );
        }
      })
      .catch((error) => {
        console.log("Error getting player state", error);
      });
  }
  return videoStarted;
};

export default handlePlayerState;
