import { FC } from "react";
import Skeleton from "react-loading-skeleton";

const MobileSkeleton: FC = () => {
  return (
    <div className="flex md:hidden justify-between items-center w-full">
      <Skeleton style={{ width: "151px", height: "27px" }} />
      <Skeleton style={{ width: "24px", height: "24px" }} />
    </div>
  );
};

export default MobileSkeleton;
