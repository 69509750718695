import React, { FC, MutableRefObject } from "react";
import SelectDropdown from "@/Components/UI/SelectDropdown/SelectDropdown";
import Loader from "@/Components/UI/Loader/Loader";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import useDebouncedSearch from "@/utils/hooks/useDebouncedSearch";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import useTransformedOptions from "./utils/hooks/useTransformedOptions";
import useFilteredVideos from "./utils/hooks/useFilteredVideos";
import { useFetchHistoryData } from "./utils/hooks/useHistoryData";
import HistoryList from "./HistoryList/HistoryList";

const History: FC<{
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}> = ({ scrollableParent }) => {
  const { loading, users } = useFetchHistoryData();
  const { searchTerm, setSearchTerm, debouncedValue, isDebouncing } =
    useDebouncedSearch("");
  const {
    dropdownOptions,
    selectedUserId,
    videos,
    setSelectedUserId,
    setVideos,
  } = useTransformedOptions(users);

  useFilteredVideos(selectedUserId, debouncedValue, users, setVideos);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUser = users.find((user) => user.signName === e.target.value);
    setSelectedUserId(selectedUser?.id);
    setSearchTerm("");
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {loading ? (
        <span className="pt-8">
          <Loader />
        </span>
      ) : (
        <>
          <div className="flex justify-start flex-wrap gap-4 items-end">
            <div className="min-w-64">
              {dropdownOptions.length > 0 && (
                <SelectDropdown
                  name="history"
                  labelText="searching by"
                  options={dropdownOptions}
                  handleChange={handleChange}
                />
              )}
            </div>
            <div className="min-w-64 md:mt-6 mb-8 md:my-8 mt-[-35px]">
              <SearchInput
                searchTerm={searchTerm}
                handleChange={handleSearchChange}
                placeholder="Search videos"
              />
            </div>
          </div>

          {!isDebouncing ? (
            videos.length > 0 ? (
              <HistoryList
                videos={videos}
                scrollableParent={scrollableParent}
              />
            ) : (
              <Paragraph
                type={ParagraphTypeEnum.p1_Small}
                className="video__container_duration"
              >
                No videos found
              </Paragraph>
            )
          ) : (
            <span className="pt-8">
              <Loader />
            </span>
          )}
        </>
      )}
    </>
  );
};

export default History;
