import { VideoItem } from "@/utils/types/video-item.type";
import { Socket } from "socket.io-client";
import { SetStateAction } from "react";

const updateVideoQueueByDragAndDrop = (
  newQueue: VideoItem[],
  isEnd: boolean = false,
  socketRef: React.MutableRefObject<Socket | null>,
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void,
  videoQueue: VideoItem[]
) => {
  setVideoQueue([videoQueue[0], ...newQueue]);
  if (socketRef.current && isEnd === true) {
    socketRef.current.emit("updateQueueByDragAndDrop", videoQueue);
  }
};

export default updateVideoQueueByDragAndDrop;
