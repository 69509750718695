import Heading from "@/Components/UI/Heading/Heading";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";

const ReadMe = () => {
  return (
    <div className="p-6 md:p-8 flex gap-2 flex-col">
      <Heading type={HeadingTypeEnum.h2_Small}>Instructions:</Heading>
      <ul className="flex flex-col gap-4">
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            Press the Play button and enjoy the radio currated by the people of
            Agiledrop. Player is global, so everyone who joins is listening to
            the same music.
          </Paragraph>
        </li>
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            When you login, you can add the songs to the playlist. Go to the
            Search tab, find a song and add it to the playlist.
          </Paragraph>
        </li>
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            If you like a song really much, add it to your favorites list
            because we have a limited number of YouTube searches and this will
            help us stay under the limit.
          </Paragraph>
        </li>
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            You can also adjust the volume, which is your local setting, all the
            other things are global.
          </Paragraph>
        </li>
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            Do not fast forward or rewind the video if you find a way to do so,
            as if you fast forward the video to the end, the video will switch
            for all users of the site. Use the website like a radio and listen
            to other people's music.
          </Paragraph>
        </li>
        <li>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>
            If you find bugs, have a feature request or an idea for future
            development add it to{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1g1WEkMkoVOuVZhRALBMqmZJhS5FnV2hN_Qc5MgAnz28/edit#gid=0"
              className="underline cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              this spreadsheet
            </a>
            .
          </Paragraph>
        </li>
      </ul>
    </div>
  );
};

export default ReadMe;
