import React, { FC, createContext, useContext, useState } from "react";

interface IToastContext {
  toastId: string | null;
  setToastId: (toastId: string | null) => void;
}

export const ToastContext = createContext<IToastContext | null>(null);

export const useToastContext = () => useContext(ToastContext);

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toastId, setToastId] = useState<string | null>(null);

  return (
    <ToastContext.Provider value={{ toastId, setToastId }}>
      {children}
    </ToastContext.Provider>
  );
};
