import { MutableRefObject, SetStateAction } from "react";

const handleVolumeChange = (
  value: number,
  setVolume: (value: SetStateAction<number>) => void,
  playerRef: MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>
) => {
  setVolume(value);

  if (playerRef.current && playerRef.current.internalPlayer) {
    playerRef.current.internalPlayer.setVolume(value);
    sessionStorage.setItem("volume", value.toString());
  }
};

export default handleVolumeChange;
