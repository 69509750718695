import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import { VideoItem } from "@/utils/types/video-item.type";
import { FC, useContext } from "react";
import YouTube, { YouTubeEvent } from "react-youtube";

interface YoutubeVideoIframeProps {
  video: VideoItem;
  opts: any;
  onEnd: ((event: YouTubeEvent<number>) => void) | undefined;
  playerRef: any;
  onReady: (event: YouTubeEvent) => void;
  onStateChange: (event: YouTubeEvent) => void;
}

const YoutubeVideoIframe: FC<YoutubeVideoIframeProps> = ({
  video,
  opts,
  onEnd,
  playerRef,
  onReady,
  onStateChange,
}) => {
  const { isPlayerVisible } = useContext(PlayerContext)!;

  return (
    <div
      className={`relative ${
        isPlayerVisible ? " w-[278px] h-[156px] shrink-0" : ""
      }`}
      style={{
        width: isPlayerVisible ? "278px" : "0px",
        height: isPlayerVisible ? "156px" : "0px",
      }}
    >
      <YouTube
        className="z-[1]"
        videoId={video.id}
        opts={opts}
        onEnd={onEnd}
        ref={playerRef}
        onReady={onReady}
        onStateChange={onStateChange}
      />
      <div className="absolute bottom-0 left-0 z-10 h-full w-full"></div>
    </div>
  );
};

export default YoutubeVideoIframe;
