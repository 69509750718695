import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import axios from "axios";

export const deleteFavoriteGroupsApi = async (
  user: string | null,
  group: GroupsInterface
) => {
  if (!user) return null;

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/favoriteGroups`,
      {
        data: {
          email: user,
          favoriteGroup: group,
        },
      }
    );
    return response.data.favoriteGroups;
  } catch (error) {
    console.error("Failed to fetch favorite list", error);
    return null;
  }
};

export default deleteFavoriteGroupsApi;
