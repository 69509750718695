import { FC } from "react";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

interface TooltipProps {
  id: string;
  content: string;
  place?: PlacesType | undefined;
}

const Tooltip: FC<TooltipProps> = ({ id, content, place = "bottom" }) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      content={content}
      className="relative z-10"
    />
  );
};

export default Tooltip;
