import { VideoItem } from "@/utils/types/video-item.type";
import { MutableRefObject, SetStateAction } from "react";
import { Socket } from "socket.io-client";

const onEnd = (
  endTriggered: boolean,
  setEndTriggered: (value: SetStateAction<boolean>) => void,
  currentVideo: VideoItem | null,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>
) => {
  if (endTriggered) return;
  setEndTriggered(true);

  const videoToRemove = currentVideo ? currentVideo : null;

  if (videoToRemove && serverEndpoint) {
    if (socketRef.current) {
      socketRef.current.emit("removeVideoBySwitching", videoToRemove);
    }
  }
};

export default onEnd;
