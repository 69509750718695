import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import { FC, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PlayerSkeleton: FC = () => {
  const { isPlayerVisible } = useContext(PlayerContext)!;

  return (
    <div className="flex flex-col xl:flex-row items-start gap-4 xl:gap-9">
      {isPlayerVisible && (
        <div>
          <Skeleton style={{ width: "17.375rem", height: "9.75rem" }} />
        </div>
      )}

      <div>
        <Skeleton
          containerClassName="block mb-2 xl:mb-4 max-h-[1rem]"
          style={{ height: "1rem", width: "12.5rem" }}
        />
        <Skeleton
          containerClassName="block flex-1 mb-4 max-h-[1.75rem]"
          style={{ height: "1.75rem", width: "90%", maxWidth: "50rem" }}
        />
        <div className="flex flex-col gap-y-6">
          <Skeleton
            count={3}
            style={{ height: "1.25rem", width: "4.5rem" }}
            containerClassName="xl:flex xl:flex-row xl:gap-x-2"
          />
          <Skeleton
            count={2}
            containerClassName="block xl:flex xl:flex-row xl:gap-x-4"
            style={{
              height: "2.25rem",
              width: "12.5rem",
              marginBottom: "0.5rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerSkeleton;
