import SelectDropdown from "@/Components/UI/SelectDropdown/SelectDropdown";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import React, { FC, ReactNode } from "react";

interface TransformedSelectDropdownProps {
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  favoriteGroups: GroupsInterface[];
  currentGroup: string;
  children?: ReactNode;
}

const TransformedSelectDropdown: FC<TransformedSelectDropdownProps> = ({
  handleChange,
  favoriteGroups,
  currentGroup,
  children,
}) => {
  const dropdownOptions =
    favoriteGroups && Array.isArray(favoriteGroups)
      ? favoriteGroups
          .map((group) => ({
            id: group.id,
            value: group.label ?? "No Label",
          }))
          .sort((a, b) => a.id - b.id)
      : [];

  return (
    <div className="relative w-full">
      <SelectDropdown
        options={dropdownOptions}
        handleChange={handleChange}
        name="Select a Group"
        labelText="Select a Group"
        className=""
        defaultSelected={currentGroup}
      />
      {children}
    </div>
  );
};

export default TransformedSelectDropdown;
