import getName from "@/utils/helpers/getName";
import beLogin from "./beLogin";
import { VideoItem } from "@/utils/types/video-item.type";
import axios from "axios";

const refreshAuth = async (
  token: any,
  setUser: (user: string | null) => void,
  setPicture: (picture: string | undefined) => void,
  setRole: (role: string | undefined) => void,
  setFavoriteUserList: (list: VideoItem) => void,
  setName: (name: string | undefined) => void
) => {
  try {
    const userInfo = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      { headers: { Authorization: `Bearer ${token}` } }
    );

    const signName = getName({
      email: userInfo.data.email,
      firstName: userInfo.data.given_name,
      lastName: userInfo.data.family_name,
    });

    beLogin(
      userInfo.data.email,
      userInfo.data.picture,
      signName,
      setUser,
      setPicture,
      setRole,
      setFavoriteUserList,
      setName
    );
  } catch (error) {
    console.error("Error refreshing auth token:", error);
  }
};

export default refreshAuth;
