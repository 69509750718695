import { User } from "@/utils/types/user.type";
import { useEffect, useState } from "react";

const useAddUserRole = (usersList: User[]) => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/users`)
      .then((response) => response.json())
      .then((json) => {
        const transformedUserList = usersList.reduce<User[]>((acc, user) => {
          const foundUser = json.users.find((u: any) => u.email === user.name);

          if (foundUser) {
            acc.push({
              ...user,
              role: foundUser.role,
            });
          } else {
            acc.push({
              ...user,
              role: "anonymous",
            });
          }

          return acc;
        }, []);

        setUsers(transformedUserList);
      });
  }, [usersList]);

  return users;
};

export default useAddUserRole;
