const logout = (
  setUser: (user: string | null) => void,
  setPicture: (picture: string | undefined) => void,
  setRole: (role: string | undefined) => void
) => {
  localStorage.removeItem("jwt");
  setUser(null);
  setPicture(undefined);
  setRole(undefined);
};

export default logout;
