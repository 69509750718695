import { useEffect, useState, MutableRefObject } from "react";
import { VideoItem } from "@/utils/types/video-item.type";
import { UserSpotlightItem } from "@/utils/types/user.type";

type Item = VideoItem | UserSpotlightItem;

export function useLimitResults<T extends Item>(
  itemList: T[],
  maxResults: number = 10
) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [displayLimit, setDisplayLimit] = useState<number>(maxResults);
  const [displayedItems, setDisplayedItems] = useState<T[]>(
    itemList.slice(0, displayLimit)
  );

  // Display new list of items on itemList or displayLimit change
  useEffect(() => {
    setDisplayedItems(itemList.slice(0, displayLimit));
  }, [itemList, displayLimit]);

  // Display next batch of items
  const handleShowMore = (): void => {
    setDisplayLimit((prevValue) => prevValue + displayLimit);
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  // Reset all items
  const handleCollapseAll = (): void => {
    setDisplayLimit(maxResults);
    setIsExpanded(false);
  };

  // Scroll page & list container back to top
  const handleBackToTop = (
    ref: MutableRefObject<HTMLDivElement | null> | null,
    behavior: ScrollBehavior = "smooth",
    timeout: number = 400
  ) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: 0,
        behavior: behavior,
      });

      ref.current!.scroll({
        top: 0,
        behavior: behavior,
      });

      setTimeout(() => {
        handleCollapseAll();
      }, timeout);
    }
  };

  return {
    displayedItems,
    isExpanded,
    setIsExpanded,
    handleShowMore,
    handleBackToTop,
    handleCollapseAll,
  };
}
