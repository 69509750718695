import { Socket } from "socket.io-client";
import toast from "react-hot-toast";

const handleNext = (
  socketRef: React.MutableRefObject<Socket | null>,
  event: string = "nextSong",
  user?: string
) => {
  toast.dismiss();

  if (user) {
    socketRef.current!.emit(event, user);
  } else {
    socketRef.current!.emit(event);
  }
};

export default handleNext;
