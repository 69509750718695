import { FC, MutableRefObject, useEffect, useState } from "react";
import { ArrowUpIcon } from "@/assets/svg/svg";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import { VideoItem } from "@/utils/types/video-item.type";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import VideoListContent from "./VideoListContent/VideoListContent";

interface VideoListProps {
  videos: VideoItem[];
  openModal: (video: VideoItem) => void;
  handleShowMore: () => void;
  isExpanded: boolean;
  handleBackToTop: (
    ref: MutableRefObject<HTMLDivElement | null>,
    behavior?: ScrollBehavior,
    timeout?: number
  ) => void;
  scrollableParent?: MutableRefObject<HTMLDivElement | null> | undefined;
  videosToShow: VideoItem[];
  favoriteGroups: GroupsInterface[] | null;
  closeModal?: () => void;
}

const VideoList: FC<VideoListProps> = ({
  videos,
  openModal,
  handleShowMore,
  isExpanded,
  handleBackToTop,
  scrollableParent,
  videosToShow,
  favoriteGroups,
  closeModal,
}) => {
  const [videoGroups, setVideoGroups] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    const newVideoGroups = new Map<string, string>();

    videos.forEach((video) => {
      const groupName = findGroupForVideo(video.id, favoriteGroups);
      newVideoGroups.set(video.id, groupName!);
    });

    setVideoGroups(newVideoGroups);
  }, [videos, favoriteGroups]);

  function findGroupForVideo(
    videoId: string,
    favoriteGroups: GroupsInterface[] | null
  ): string | null {
    if (!favoriteGroups) return null;

    for (const group of favoriteGroups) {
      if (group.id === 1 && group.label === "All Videos") continue;

      const videoFound = group.videos.some((video) => video.id === videoId);
      if (videoFound) {
        return group.label;
      }
    }

    return null;
  }

  return (
    <div className="FavoritePlayList__results-container mt-3">
      {videos.map((video, index) => (
        <div
          key={video.id + "favorite"}
          className={`flex flex-col py-6 ${
            videos.length - 1 !== index ? "border-b border-accent-gray300" : ""
          }`}
        >
          <VideoListContent
            video={video}
            openModal={openModal}
            closeModal={closeModal}
            videoGroups={videoGroups}
          />
        </div>
      ))}

      {videos.length < videosToShow.length && (
        <DefaultButton onClick={handleShowMore}>SHOW MORE</DefaultButton>
      )}

      {isExpanded && (
        <div className="back-to-top sticky bottom-0 right-0 z-10 w-2.5 ml-auto">
          <span
            className="cursor-pointer inline-block relative"
            style={{ top: "-0.5rem" }}
            onClick={() => handleBackToTop(scrollableParent!)}
          >
            <ArrowUpIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default VideoList;
