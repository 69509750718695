import { toggleFavoriteApi } from "@/utils/api/toggleFavoriteApi";
import { VideoItem } from "@/utils/types/video-item.type";
import { SetStateAction } from "react";
import toast from "react-hot-toast";

const toggleFavorite = (
  video: VideoItem,
  user: string | null,
  setIsFavoriteToggled: (value: SetStateAction<boolean>) => void
) => {
  if (!user) {
    toast.error(`You are not authorized!`);
    return;
  }
  toggleFavoriteApi(user, video)
    .then((data) => {
      if (data.message.includes("added")) {
        toast.success("Video was added to favorite!");
      } else {
        toast.success("Video was deleted from favorite!");
      }
      setIsFavoriteToggled((prevState) => !prevState);
    })
    .catch((error) => {
      toast.error(`There was an error toggling the favorite video:${error}`);
    });
};

export default toggleFavorite;
