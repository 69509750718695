import React, { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  searchTerm: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText?: string;
  placeholder?: string;
  maxLength?: number;
  noMarginTop?: boolean;
}

const SearchInput: FC<SearchInputProps> = ({
  searchTerm,
  handleChange,
  placeholder,
  maxLength,
  labelText,
  noMarginTop = false,
  ...props
}) => {
  const { mode } = useContext(ThemeContext);

  const themeStyles = {
    dark: {
      bg: "background-bgDark100",
      border: "accent-gray300",
      activeBorder: "focus:border-accent-gray100",
      text: "text-neutral-white font-nunitoSans font-normal leading-6",
      labelText: "text-neutral-white",
    },
    light: {
      bg: "neutral-white",
      border: "accent-gray100",
      activeBorder: "focus:border-accent-gray300",
      text: "text-primary-blackPetrol font-nunitoSans font-normal leading-6",
      labelText: "text-primary-blackPetrol",
    },
  };

  const currentTheme = themeStyles[mode];

  return (
    <>
      {labelText && (
        <label
          htmlFor="searchInput"
          className={`${currentTheme.labelText} font-semibold text-[12px]`}
        >
          {labelText.toUpperCase()}
        </label>
      )}
      <input
        name="searchInput"
        type="text"
        value={searchTerm}
        onChange={handleChange}
        className={`border border-${currentTheme.border} ${
          noMarginTop ? "" : "mt-2"
        } py-2 ps-3 pe-4 rounded-md w-full h-9 bg-${currentTheme.bg} ${
          currentTheme.text
        } focus:outline-none ${
          currentTheme.activeBorder
        } focus:shadow-none focus:ring-0 text-[0.875rem]`}
        placeholder={placeholder}
        maxLength={maxLength}
        {...props}
      />
    </>
  );
};

export default SearchInput;
