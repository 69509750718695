interface getNameProps {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  email: string;
}

export default function getName({
  email,
  firstName,
  lastName,
}: getNameProps): string {
  if (firstName && lastName) {
    return `${firstName} ${lastName.charAt(0)}.`;
  } else {
    return email;
  }
}
