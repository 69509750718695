import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SidebarSkeleton: FC = () => {
  return (
    <>
      <Skeleton
        count={4}
        containerClassName="flex gap-x-0.5 ml-[-2rem] mr-[-2.125rem]"
        style={{ height: "5.375rem", width: "100%" }}
      />
      <div className="pt-6 flex gap-2 flex-col">
        <Skeleton
          count={2}
          containerClassName="flex-1"
          style={{ height: "35px", marginTop: "0.5rem" }}
        />
      </div>
    </>
  );
};

export default SidebarSkeleton;
