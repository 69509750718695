import { MutableRefObject, SetStateAction } from "react";
import toast from "react-hot-toast";
import socketIOClient, { Socket } from "socket.io-client";
import { VideoItem } from "../types/video-item.type";
import { User } from "../types/user.type";

const initializeSocketConnection = (
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>,
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void,
  currentVideo: VideoItem | null,
  setCurrentVideo: (value: SetStateAction<VideoItem | null>) => void,
  setUsersList: (value: SetStateAction<User[]>) => void,
  setRecentlyPlayedQueue: (value: SetStateAction<VideoItem[]>) => void,
  setIsPlaying: (value: SetStateAction<boolean>) => void,
  playerRef: MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  setUserCount: (value: SetStateAction<number>) => void
) => {
  if (!serverEndpoint) {
    console.error("SERVER_ENDPOINT is not defined");
    return;
  }
  socketRef.current = socketIOClient(serverEndpoint);

  socketRef.current.on("updateQueue", (queue) => {
    setVideoQueue(queue);
    if (currentVideo === null && queue.length > 0) {
      setCurrentVideo(queue[0]);
    }
  });

  socketRef.current.on("getAllUsers", (users) => {
    setUsersList(users);
  });

  socketRef.current.on("updateRecentlyPlayedQueue", (queue) => {
    setRecentlyPlayedQueue(queue);
  });

  socketRef.current.on("setPlayPause", (isPlayingFromServer) => {
    setIsPlaying(isPlayingFromServer);
    if (isPlayingFromServer) {
      playerRef.current?.internalPlayer.playVideo();
    } else {
      playerRef.current?.internalPlayer.pauseVideo();
    }
  });

  socketRef.current.on("videoExists", () => {
    toast.error("This video is already in the queue!");
  });

  socketRef.current.on("videoAdded", () => {
    toast.success("Video was added!");
  });

  socketRef.current.on("getUsersCount", (count) => {
    setUserCount(count);
  });

  if (sessionStorage.getItem("isPaused") === "true") {
    sessionStorage.setItem("isPaused", "false");
  }
};

export default initializeSocketConnection;
