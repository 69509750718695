import React, { FC, useContext } from "react";
import { Tooltip } from "react-tooltip";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";

interface DefaultButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  tooltipId?: string;
  tooltipContent?: string;
  showTooltip?: boolean;
}

const stylesByType = {
  light: {
    default: "text-button_Default bg-button-btnLight",
    hover: "hover:bg-button-btnLightHover",
    focus: "focus:ring-offset-2 focus:ring-4 focus:ring-button-btnLight",
  },
  dark: {
    default: "text-button_Default bg-button-btnDark",
    hover: "hover:bg-button-btnDarkHover",
    focus: "focus:ring-offset-2 focus:ring-2 focus:ring-button-btnDark",
  },
};

const DefaultButton: FC<DefaultButtonProps> = ({
  className = "",
  children,
  tooltipContent = "",
  tooltipId = "",
  showTooltip = false,
  ...props
}) => {
  const { mode } = useContext(ThemeContext);
  return (
    <>
      <button
        data-tooltip-id={tooltipId}
        className={`${stylesByType[mode].default} ${
          props.disabled ? "" : stylesByType[mode].hover
        } ${
          stylesByType[mode].focus
        } flex justify-center items-center font-nunitoSans w-full px-3 py-2 text-neutral-white rounded-[5px] ${className}`}
        {...props}
      >
        {children}
      </button>
      {showTooltip && props.disabled && (
        <Tooltip id={tooltipId} content={tooltipContent} place={"bottom"} />
      )}
    </>
  );
};

export default DefaultButton;
