import { FC, MutableRefObject, useState } from "react";
import SelectDropdown from "@/Components/UI/SelectDropdown/SelectDropdown";
import SpotlightStatsEnum from "@/utils/enums/spotlight-stats-type.enum";
import LeaderboardContent from "./LeaderboardContent/LeaderboardContent";
import useFetchLeaderboardData from "./utils/hooks/useFetchLeaderboardData";
import leaderboardOptionList from "./utils/data/leaderboardOptionList";

const Leaderboard: FC<{
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}> = ({ scrollableParent }) => {
  const [dataType, setDataType] = useState<SpotlightStatsEnum>(
    SpotlightStatsEnum.USER_MOST_SONGS
  );
  const [loading, data, topThree, setData, setTopThree] =
    useFetchLeaderboardData(dataType);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (dataType === e.target.id) return;
    setDataType(e.target.id as SpotlightStatsEnum);
    setData(null);
    setTopThree(null);
  };

  return (
    <>
      <div className="max-w-64">
        <SelectDropdown
          name="leaderboard"
          labelText="filter by"
          options={leaderboardOptionList}
          handleChange={handleChange}
        />
      </div>

      <LeaderboardContent
        isLoading={loading}
        topThree={topThree}
        data={data}
        dataType={dataType}
        scrollableParent={scrollableParent}
      />
    </>
  );
};

export default Leaderboard;
