import { FC, useContext, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import { GoogleIcon } from "@/assets/svg/svg";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { UserContext } from "@/context/UserContext/UserContext";
import logout from "./utils/logout";
import refreshAuth from "./utils/refreshAuth";
import { getUserData } from "./utils/getUserData";
import UserAuthControls from "./UserAuthControls/UserAuthControls";

interface AuthComponentProps {
  isMobile: boolean;
}

const AuthComponent: FC<AuthComponentProps> = ({ isMobile }) => {
  const userContext = useContext(UserContext)!;
  const favoriteContext = useContext(FavoriteContext)!;

  const { user, setUser, setPicture, setRole, setName, userToken } =
    userContext;
  const { setFavoriteUserList } = favoriteContext;

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      refreshAuth(
        tokenResponse.access_token,
        setUser,
        setPicture,
        setRole,
        setFavoriteUserList,
        setName
      );
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    if (userToken) {
      getUserData(userContext, favoriteContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"flex items-center gap-4"}>
      {user ? (
        <UserAuthControls
          isMobile={isMobile}
          handleClick={() => logout(setUser, setPicture, setRole)}
        />
      ) : (
        <DefaultButton onClick={() => googleLogin()}>
          <span className="flex items-center gap-2">
            <GoogleIcon /> LOG IN
          </span>
        </DefaultButton>
      )}
    </div>
  );
};

export default AuthComponent;
