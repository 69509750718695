import { FC, useContext } from "react";
import { usePWAInstall } from "react-use-pwa-install";
import classNames from "classnames";
import { InstallIcon } from "@/assets/svg/svg";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import AuthComponent from "@/Components/Header/AuthComponent/AuthComponent";
import DarkModeToggle from "@/Components/UI/DarkModeToggle/DarkModeToggle";
import PlayerToggle from "@/Components/UI/PlayerToggle/PlayerToggle";
import Logo from "@/Components/UI/Logo/Logo";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

interface MobileMenuProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

const MobileMenu: FC<MobileMenuProps> = ({ opened, setOpened }) => {
  const { mode } = useContext(ThemeContext);

  const menuClass = classNames(
    "fixed h-screen top-[60px] inset-0 bg-black z-50 flex overflow-y-hidden overflow-x-hidden flex-col items-start px-8 py-4 gap-4",
    {
      "bg-background-bgDark100": mode === "dark",
      "bg-background-bgLight200": mode !== "dark",
    }
  );

  const install = usePWAInstall();

  return (
    <div className="flex justify-between items-center w-full md:hidden">
      <Logo />
      <div className="flex gap-6 items-center">
        {install && (
          <span onClick={install}>
            <InstallIcon />
          </span>
        )}
        <BurgerMenu opened={opened} setOpened={setOpened} />
      </div>

      {opened && (
        <div className={menuClass}>
          <AuthComponent isMobile={true} />
          <div className="flex gap-4">
            <DarkModeToggle />
            <PlayerToggle />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
