import React, { FC, useContext } from "react";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { VideoItem } from "@/utils/types/video-item.type";
import { CloseIcon } from "@/assets/svg/svg";
import Heading from "@/Components/UI/Heading/Heading";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";

interface ModalProps {
  isModalOpen: boolean;
  messageInput: string;
  setMessageInput: (messageInput: string) => void;
  selectedVideo: VideoItem | undefined;
  sendMessage: (video: VideoItem, messageInput: string) => void;
  closeModal: () => void;
}

const Modal: FC<ModalProps> = ({
  isModalOpen,
  sendMessage,
  setMessageInput,
  selectedVideo,
  messageInput,
  closeModal,
}) => {
  const { mode } = useContext(ThemeContext)!;

  if (!isModalOpen) return null;

  const handleFormClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-background-bgDark300 bg-opacity-90 flex justify-center items-center z-50"
      onClick={() => closeModal()}
    >
      <form
        onSubmit={() => sendMessage(selectedVideo!, messageInput)}
        className={`${
          mode === "dark"
            ? "bg-background-bgDark100"
            : "bg-background-bgLight100"
        } relative p-6 rounded-md shadow-lg flex flex-col gap-6`}
        onClick={handleFormClick}
      >
        <Heading type={HeadingTypeEnum.h2_Small} className={"text-center"}>
          Message
        </Heading>
        <SearchInput
          searchTerm={messageInput}
          placeholder="max 150 characters..."
          handleChange={(e) => setMessageInput(e.target.value)}
          maxLength={150}
        />
        <DefaultButton type="submit">SUBMIT</DefaultButton>
        <span onClick={() => closeModal()}>
          <CloseIcon className={"absolute top-2 right-2 cursor-pointer"} />
        </span>
      </form>
    </div>
  );
};

export default Modal;
