import { FC } from "react";
import { FakeSelectProps } from "@/utils/types/fake-select.type";
import FakeSelectSelectedOption from "./FakeSelectSelectedOption/FakeSelectSelectedOption";
import FakeSelectOptionList from "./FakeSelectOptionList/FakeSelectOptionList";
import "./FakeSelect.css";

const FakeSelect: FC<FakeSelectProps> = ({
  name,
  selected,
  currentTheme,
  handleClick,
  handleOptionChange,
  dropdownExpanded,
  options,
}) => {
  return (
    <div className="select__list mt-2">
      <FakeSelectSelectedOption
        handleClick={handleClick!}
        currentTheme={currentTheme}
        dropdownExpanded={dropdownExpanded!}
        selected={selected!}
      />
      {dropdownExpanded && (
        <FakeSelectOptionList
          currentTheme={currentTheme}
          options={options!}
          handleOptionChange={handleOptionChange!}
          name={name!}
        />
      )}
    </div>
  );
};

export default FakeSelect;
