import React, {
  FC,
  useState,
  useContext,
  useRef,
  MutableRefObject,
} from "react";
import { LikesIcon, ReadMeIcon, SearchIcon, UsersIcon } from "@/assets/svg/svg";
import YouTubeSearch from "./YouTubeSearch/YouTubeSearch";
import FavoritePlayList from "./FavoritePlayList/FavoritePlayList";
import ReadMe from "./ReadMe/ReadMe";
import Users from "./Users/Users";
import UserSettingsTab from "./UI/UserSettingsTab/UserSettingsTab";
import UserSettingsTabTypeEnum from "@/utils/enums/user-settings-tab-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import { User } from "@/utils/types/user.type";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserContext } from "@/context/UserContext/UserContext";

interface UserSettingProps {
  onVideoSelect: (video: VideoItem) => void;
  toggleFavorite: (video: VideoItem) => void;
  isFavoriteToggled: boolean;
  usersList: User[];
  userCount: number;
  scrollableParent?: MutableRefObject<HTMLDivElement | null> | undefined;
  setIsFavoriteToggled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TAB_CONFIG = [
  {
    id: UserSettingsTabTypeEnum.YouTubeSearch,
    title: "SEARCH",
    icon: <SearchIcon hover={false} />,
    component: () => <YouTubeSearch />,
  },
  {
    id: UserSettingsTabTypeEnum.FavoritePlayList,
    title: "LIKES",
    icon: <LikesIcon hover={false} />,
    component: (props: UserSettingProps) => <FavoritePlayList {...props} />,
    isUser: true,
  },
  {
    id: UserSettingsTabTypeEnum.ReadMe,
    title: "READ ME",
    icon: <ReadMeIcon hover={false} />,
    component: () => <ReadMe />,
  },
  {
    id: UserSettingsTabTypeEnum.Users,
    title: "USERS",
    icon: <UsersIcon hover={false} />,
    component: (props: UserSettingProps) => <Users {...props} />,
    isAdmin: true,
  },
];

const UserSetting: FC<UserSettingProps> = ({
  onVideoSelect,
  toggleFavorite,
  isFavoriteToggled,
  usersList,
  userCount,
  setIsFavoriteToggled,
}) => {
  const { user, role } = useContext(UserContext)!;
  const { mode } = useContext(ThemeContext);

  const [activeTab, setActiveTab] = useState(
    UserSettingsTabTypeEnum.YouTubeSearch
  );
  const [hoveredTab, setHoveredTab] = useState<UserSettingsTabTypeEnum | null>(
    null
  );
  const [touchStart, setTouchStart] = useState<number | null>(null);

  const scrollableParent = useRef<HTMLDivElement | null>(null);

  // Functions for touch scrolling
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStart === null) {
      return;
    }
    const touchEnd = e.touches[0].clientX;
    const deltaX = touchEnd - touchStart;
    const scrollContainer = e.currentTarget;

    scrollContainer.scrollLeft -= deltaX;
    setTouchStart(touchEnd);
  };

  const handleTouchEnd = () => {
    setTouchStart(null);
  };

  return (
    <div
      ref={scrollableParent}
      className={`border ${
        mode === "dark"
          ? "bg-background-bgDark100 border-accent-transparent"
          : "bg-background-bgLight100 border-solid border-accent-gray200 border shadow-primary"
      } rounded-md xl:min-w-[384px] xl:max-w-[384px] 2xl:min-w-[557px] 2xl:w-[557px] scrollbar-none max-h-[1000px] md:max-h-[750px] xl:max-h-none xl:h-[calc(100vh-140px)] xl:min-h-[1000px] overflow-y-scroll`}
    >
      <div
        className="flex overflow-auto scrollbar-none"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {TAB_CONFIG.map((tab, index) => {
          const isLastTab = index === TAB_CONFIG.length - 1;
          if (
            (!tab.isUser && !tab.isAdmin) ||
            (tab.isUser && user) ||
            (tab.isAdmin && role === "admin")
          ) {
            return (
              <React.Fragment key={tab.id}>
                <UserSettingsTab
                  onMouseEnter={() => setHoveredTab(tab.id)}
                  onMouseLeave={() => setHoveredTab(null)}
                  onClick={() => setActiveTab(tab.id)}
                  isLastTab={isLastTab}
                  hoveredTab={hoveredTab}
                  activeTab={activeTab}
                  tab={tab}
                />
              </React.Fragment>
            );
          }
          return null;
        })}
      </div>
      <div>
        {TAB_CONFIG.map((tab) =>
          activeTab === tab.id ? (
            <tab.component
              key={tab.id}
              onVideoSelect={onVideoSelect}
              toggleFavorite={toggleFavorite}
              isFavoriteToggled={isFavoriteToggled}
              usersList={usersList}
              userCount={userCount}
              scrollableParent={
                activeTab === UserSettingsTabTypeEnum.FavoritePlayList
                  ? scrollableParent
                  : undefined
              }
              setIsFavoriteToggled={setIsFavoriteToggled}
            />
          ) : null
        )}
      </div>
    </div>
  );
};

export default UserSetting;
