const textStyles = {
  p1_Large: {
    light:
      "text-accent-gray400 font-nunitoSans text-[16px] font-normal leading-6",
    dark: "text-accent-gray100 font-nunitoSans text-[16px] font-normal leading-6",
  },
  p1_Small: {
    light:
      "text-accent-gray400 font-nunitoSans text-[14px] md:text-[16px] font-normal leading-5 md:leading-6",
    dark: "text-accent-gray100 font-nunitoSans text-[14px] md:text-[16px] font-normal leading-5 md:leading-6",
  },
  p2_Default: {
    light:
      "text-accent-gray400 font-nunitoSans text-[14px] font-normal leading-5",
    dark: "text-accent-gray200 font-nunitoSans text-[14px] font-normal leading-5",
  },
  p3_Default: {
    light:
      "text-primary-blackPetrol font-nunitoSans text-[12px] font-normal leading-4",
    dark: "text-neutral-white font-nunitoSans text-[12px] font-normal leading-4",
  },
  p3_Bold: {
    light: "text-[12px] font-bold leading-4",
    dark: "",
  },
};

export default textStyles;
