import { useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import logoLight from "@/assets/img/LogoLight.svg";
import logoDark from "@/assets/img/LogoDark.svg";

const Logo = () => {
  const { mode } = useContext(ThemeContext);

  return (
    <img
      width="151"
      height="27"
      src={mode === "dark" ? logoDark : logoLight}
      alt="Agiledrop logo"
    />
  );
};

export default Logo;
