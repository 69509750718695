import React from "react";
import useCurrentTime from "@/utils/hooks/useCurrentTime";
import { YouTubePlayerRef } from "@/utils/types/youtube-player-ref-type";

interface TimerProps {
  playerRef: YouTubePlayerRef;
  timerState: TimerStateEnum;
}

export enum TimerStateEnum {
  START = "start",
  STOP = "stop",
}

const Timer: React.FC<TimerProps> = React.memo(({ playerRef, timerState }) => {
  const { currentTime, startTimer, stopTimer } = useCurrentTime(playerRef);
  React.useEffect(() => {
    if (timerState === TimerStateEnum.START) startTimer();
    else if (timerState === TimerStateEnum.STOP) stopTimer();

    return () => {
      stopTimer();
    };
  }, [timerState]);

  return <>{currentTime}</>;
});

export default Timer;
