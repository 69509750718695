import React, { FC, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import { VideoItem } from "@/utils/types/video-item.type";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import useDebouncedSearch from "@/utils/hooks/useDebouncedSearch";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import Loader from "@/Components/UI/Loader/Loader";
import PlayerList from "../PlayerList/PlayerList";

interface RecentlyPlayedProps {
  recentlyPlayedQueue: VideoItem[];
  updateVideoQueue: (newQueue: VideoItem[], isEnd?: boolean) => void;
  handleLike: (video: VideoItem) => void;
  handleDislike: (video: VideoItem) => void;
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void;
  serverEndpoint: string | undefined;
  socketRef: React.MutableRefObject<Socket | null>;
  playerRef: any;
  scrollableParent: React.MutableRefObject<HTMLDivElement | null>;
  onVideoSelect: (video: VideoItem) => void;
}

const RecentlyPlayed: FC<RecentlyPlayedProps> = ({
  recentlyPlayedQueue,
  updateVideoQueue,
  handleLike,
  handleDislike,
  setVideoQueue,
  serverEndpoint,
  socketRef,
  playerRef,
  scrollableParent,
  onVideoSelect,
}) => {
  const { searchTerm, setSearchTerm, debouncedValue, isDebouncing } =
    useDebouncedSearch("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredVideos = recentlyPlayedQueue.filter((video) =>
    video.title.toLowerCase().includes(debouncedValue.toLowerCase())
  );

  return (
    <>
      <div className="flex justify-start flex-wrap gap-4 items-end">
        <div className="min-w-64 mt-6 mb-8 my-8">
          <SearchInput
            searchTerm={searchTerm}
            handleChange={handleSearchChange}
            placeholder="Search videos"
          />
        </div>
      </div>

      {!isDebouncing ? (
        filteredVideos.length > 0 ? (
          <PlayerList
            videoQueue={filteredVideos}
            updateVideoQueue={updateVideoQueue}
            isRecentlyPlayed={true}
            handleLike={handleLike}
            handleDislike={handleDislike}
            setVideoQueue={setVideoQueue}
            serverEndpoint={serverEndpoint}
            socketRef={socketRef}
            playerRef={playerRef}
            scrollableParent={scrollableParent}
            onVideoSelect={onVideoSelect}
          />
        ) : (
          <Paragraph
            type={ParagraphTypeEnum.p1_Small}
            className="video__container_duration"
          >
            No videos found
          </Paragraph>
        )
      ) : (
        <span className="pt-8">
          <Loader />
        </span>
      )}
    </>
  );
};

export default RecentlyPlayed;
