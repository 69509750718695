import durationInMinutes from "@/utils/helpers/durationInMinutes";
import { formatDuration } from "@/utils/helpers/formatDuration";

const fetchVideoInfo = async (videoId: string) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const url = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,statistics,snippet&id=${videoId}&key=${apiKey}`;

  const response = await fetch(url);
  const data = await response.json();

  if (data.items && data.items.length > 0) {
    const video = data.items[0];

    const durationMins = durationInMinutes(video?.contentDetails?.duration);

    // Check if the video is age restricted
    const isAgeRestricted =
      video?.contentDetails?.contentRating?.ytRating === "ytAgeRestricted";

    if (durationMins > 20 || durationMins === 0 || isAgeRestricted) return null;

    const videoItem = {
      id: video.id,
      title: video.snippet.title,
      channelTitle: video.snippet.channelTitle,
      duration: formatDuration(video.contentDetails.duration),
    };
    return videoItem;
  } else {
    throw new Error("Video not found");
  }
};

export default fetchVideoInfo;
