import React, { FC, useContext, useState } from "react";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { VideoItem } from "@/utils/types/video-item.type";
import { CloseIcon } from "@/assets/svg/svg";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import addVideoToFavoriteGroupApi from "../../utils/api/addVideoToFavoriteGroupApi";
import { UserContext } from "@/context/UserContext/UserContext";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import toast from "react-hot-toast";
import { FavoriteGroupsActionEnum } from "@/utils/enums/favorite-groups-action.enum";

interface FavoritePlayListModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  video: VideoItem | null;
  favoriteGroups: GroupsInterface[] | null;
}

const FavoritePlayListModal: FC<FavoritePlayListModalProps> = ({
  isModalOpen,
  closeModal,
  video,
  favoriteGroups,
}) => {
  const { mode } = useContext(ThemeContext)!;
  const { user } = useContext(UserContext)!;

  const { dispatch } = useFavoriteGroupsContext();

  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  if (!isModalOpen) return null;

  const handleGroupSelect = (groupId: number) => {
    setSelectedGroupId(groupId);
  };

  const handleSubmit = async () => {
    updateVideoList(selectedGroupId, video!);
  };

  const handleFormClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const updateVideoList = async (
    newGroupId: number | null,
    video: VideoItem
  ) => {
    const response = await addVideoToFavoriteGroupApi(user, newGroupId, video!);

    if (response?.message) {
      toast.success(response.message);
      dispatch({
        type: FavoriteGroupsActionEnum.UPDATE_GROUP,
        payload: response.updatedGroup,
      });
    } else {
      toast.error("Failed to update the group with new video.");
    }

    closeModal();
  };

  return (
    <div
      className="fixed inset-0 bg-background-bgDark300 bg-opacity-90 flex justify-center items-center z-50"
      onClick={() => closeModal()}
    >
      <div
        className={`${
          mode === "dark"
            ? "bg-background-bgDark100"
            : "bg-background-bgLight100"
        } relative p-6 rounded-md shadow-lg flex flex-col gap-6`}
        onClick={handleFormClick}
      >
        <span onClick={() => closeModal()}>
          <CloseIcon className={"absolute top-2 right-2 cursor-pointer"} />
        </span>

        <div className="flex gap-4 flex-wrap justify-start max-w-md">
          {favoriteGroups?.map((group) => (
            <DefaultButton
              key={group.id}
              onClick={() => handleGroupSelect(group.id)}
              className={`${
                selectedGroupId === group.id ? "selected-class" : ""
              } rounded-full !w-auto`}
            >
              {group.label}
            </DefaultButton>
          ))}
        </div>

        <DefaultButton onClick={handleSubmit}>Submit</DefaultButton>
      </div>
    </div>
  );
};

export default FavoritePlayListModal;
