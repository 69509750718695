import { FC, MutableRefObject, useContext, useRef } from "react";
import { ArrowUpIcon, DislikeIcon, LikeIcon } from "@/assets/svg/svg";
import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import Heading from "@/Components/UI/Heading/Heading";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { useLimitResults } from "@/utils/hooks/useLimitResults";
import { VideoItem } from "@/utils/types/video-item.type";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";

interface HistoryListProps {
  videos: VideoItem[];
  scrollableParent: MutableRefObject<HTMLDivElement | null>;
}

const HistoryList: FC<HistoryListProps> = ({ videos, scrollableParent }) => {
  const { mode } = useContext(ThemeContext);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const { displayedItems, isExpanded, handleShowMore, handleBackToTop } =
    useLimitResults(videos, 10);

  return (
    <div ref={scrollableRef}>
      <ul>
        {videos &&
          displayedItems.map((video, id) => (
            <li
              key={id}
              className={`flex items-center py-4 ${
                mode === "dark"
                  ? id < videos.length - 1
                    ? "text-neutral-white border-b-[1px] border-b-solid border-b-accent-gray300"
                    : "text-neutral-white"
                  : id < videos.length - 1
                  ? "text-primary-blackPetrol border-b-[1px] border-b-solid border-b-accent-gray100"
                  : "text-primary-blackPetrol"
              }`}
            >
              <div className="flex flex-col md:flex-row justify-between w-full">
                <div className="flex flex-col gap-2">
                  <Heading type={HeadingTypeEnum.h2_Small}>
                    {video.title}
                  </Heading>
                  <div className="flex flex-col md:flex-row gap-1 md:gap-3 md:items-center">
                    <Paragraph
                      type={ParagraphTypeEnum.p1_Small}
                      className="video__container_duration"
                    >
                      Length: {video.duration}
                    </Paragraph>
                    <div
                      className={`${
                        mode === "dark"
                          ? "bg-neutral-white"
                          : "bg-accent-gray400"
                      } hidden md:block w-2 h-2  rounded-full`}
                    ></div>
                    <div className="flex gap-3 items-center">
                      <div className="flex items-center">
                        <span
                          className={`hover:scale-125 transition-transform duration-300`}
                        >
                          <LikeIcon isLiked={false} />
                        </span>
                        <Paragraph
                          type={ParagraphTypeEnum.p1_Small}
                          className="video__added"
                        >
                          {video.likes}
                        </Paragraph>
                      </div>
                      <div className="flex items-center">
                        <span
                          className={`hover:scale-125 transition-transform duration-300`}
                        >
                          <DislikeIcon isDisliked={false} />
                        </span>

                        <Paragraph
                          type={ParagraphTypeEnum.p1_Small}
                          className="video__added"
                        >
                          {video.dislikes}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
                {(video as VideoItem).title && (
                  <div className="flex items-center gap-x-4 mt-2 md:mt-0 md:pl-1">
                    <ActionBar
                      video={video as VideoItem}
                      tooltipId={TooltipTypeEnum.HISTORY}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        {displayedItems.length < videos.length && (
          <DefaultButton onClick={handleShowMore}>SHOW MORE</DefaultButton>
        )}
      </ul>
      {isExpanded && (
        <div className="back-to-top sticky bottom-0 right-0 w-2.5 z-10 ml-auto">
          <span
            className="cursor-pointer inline-block relative"
            style={{ top: "1.25rem" }}
            onClick={() => handleBackToTop(scrollableParent)}
          >
            <ArrowUpIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default HistoryList;
