import { MutableRefObject, SetStateAction } from "react";

const pauseVideo = (
  playerRef: MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  setIsPlaying: (value: SetStateAction<boolean>) => void
) => {
  if (playerRef.current) {
    playerRef.current.internalPlayer.pauseVideo();
    setIsPlaying(false);
  }
};

export default pauseVideo;
