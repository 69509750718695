import { FC, ReactNode, useContext } from "react";
import {
  AddIcon,
  LnkExternalIcon,
  StarEmptyIcon,
  StarSolidIcon,
} from "@/assets/svg/svg";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import { UserContext } from "@/context/UserContext/UserContext";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { onVideoSelect, toggleFavorite } from "@/utils/services/video";
import { VideoItem } from "@/utils/types/video-item.type";

interface ActionBarProps {
  video: VideoItem;
  tooltipId: TooltipTypeEnum;
  showAddToPlaylistIcon?: boolean;
  showExternalLinkIcon?: boolean;
  showLikesIcon?: boolean;
  children?: ReactNode;
}

const ActionBar: FC<ActionBarProps> = ({
  video,
  tooltipId,
  showAddToPlaylistIcon = true,
  showExternalLinkIcon = true,
  showLikesIcon = true,
  children,
}) => {
  const { favoriteUserList } = useContext(FavoriteContext)!;
  const { user, name } = useContext(UserContext)!;
  const { socketRef, serverEndpoint, setIsFavoriteToggled } =
    useContext(PlayerContext)!;

  const isVideoFavorite = (video: VideoItem) => {
    return favoriteUserList.some(
      (favoriteVideo) => favoriteVideo.id === video.id
    );
  };

  return (
    <>
      {showLikesIcon && (
        <span
          className="cursor-pointer"
          onClick={() => toggleFavorite(video, user, setIsFavoriteToggled)}
        >
          {isVideoFavorite(video) ? (
            <StarSolidIcon id={`${video.id}${tooltipId}`} />
          ) : (
            <StarEmptyIcon id={`${video.id}${tooltipId}`} />
          )}
        </span>
      )}

      {children}
      {showAddToPlaylistIcon && (
        <span
          className="cursor-pointer"
          onClick={() =>
            onVideoSelect(
              {
                id: video.id,
                title: video.title,
                duration: video.duration,
                ...(user ? { added: name } : {}),
                likes: video.likes,
                dislikes: video.dislikes,
                userReactions: video.userReactions,
              },
              serverEndpoint,
              socketRef
            )
          }
        >
          <AddIcon id={`${video.id}${tooltipId}`} />
        </span>
      )}
      {showExternalLinkIcon && (
        <LnkExternalIcon ytVideoId={video.id} tooltipId={tooltipId} />
      )}
    </>
  );
};

export default ActionBar;
