import { FC } from "react";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import {
  AdminRoleIcon,
  AnonymousRoleIcon,
  UserRoleIcon,
  UsersIcon,
} from "@/assets/svg/svg";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import UserRoleTypeEnum from "@/utils/enums/user-role-type.enum";
import useAddUserRole from "@/utils/hooks/useAddUserRole";
import { User } from "@/utils/types/user.type";
import Tooltip from "@/Components/UI/Tooltip/Tooltip";

interface UsersProps {
  usersList: User[];
  userCount: number;
}

const Users: FC<UsersProps> = ({ usersList, userCount }) => {
  const users = useAddUserRole(usersList);

  return (
    <div className="p-6 md:p-8 flex gap-2 flex-col">
      <div className="flex gap-2 items-center relative">
        <div className="relative" data-tooltip-id={`user_settings_UsersIconId`}>
          <UsersIcon hover={false} />
          <Tooltip id={`user_settings_UsersIconId`} content={"Total users"} />
        </div>

        <Paragraph type={ParagraphTypeEnum.p1_Small}>{userCount}</Paragraph>
      </div>
      <ul>
        {users.map((user, index) => {
          return (
            <li key={index} className="flex gap-2 items-center">
              <Paragraph
                type={ParagraphTypeEnum.p1_Small}
                className="flex gap-2 items-center"
              >
                -{" "}
              </Paragraph>
              {user.role === UserRoleTypeEnum.ADMIN ? (
                <AdminRoleIcon />
              ) : user.role === UserRoleTypeEnum.USER ? (
                <UserRoleIcon />
              ) : (
                <AnonymousRoleIcon />
              )}{" "}
              <Paragraph type={ParagraphTypeEnum.p1_Small}>
                {user.name}
              </Paragraph>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Users;
