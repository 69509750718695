import { VideoItem } from "@/utils/types/video-item.type";
import { MutableRefObject, SetStateAction } from "react";
import toast from "react-hot-toast";
import { Socket } from "socket.io-client";

const removeVideoFromQueue = (
  deletedVideo: VideoItem,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>,
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void,
  videoQueue: VideoItem[],
  isRecentlyPlayed = false
) => {
  if (!serverEndpoint) {
    console.error("SERVER_ENDPOINT is not defined");
    return;
  }
  if (socketRef.current) {
    socketRef.current.emit("removeVideo", deletedVideo, isRecentlyPlayed);
  }

  setVideoQueue(videoQueue.filter((video) => video.id !== deletedVideo.id));
  toast.success("Video was deleted!");
};

export default removeVideoFromQueue;
