import React, { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import textStyles from "./utils/textStyles";

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  type:
    | ParagraphTypeEnum.p1_Large
    | ParagraphTypeEnum.p1_Small
    | ParagraphTypeEnum.p2_Default
    | ParagraphTypeEnum.p3_Default
    | ParagraphTypeEnum.p3_Bold;
  children: React.ReactNode;
  className?: string;
}

const Paragraph: FC<ParagraphProps> = ({
  type = ParagraphTypeEnum.p2_Default,
  children,
  className = "",
  ...props
}) => {
  const { mode } = useContext(ThemeContext);

  const textStyle = textStyles[type][mode];

  return (
    <p
      className={`${textStyle} text-${type}  tracking-tighter ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};

export default Paragraph;
