import SpotlightStatsEnum from "@/utils/enums/spotlight-stats-type.enum";

const leaderboardOptionList: {
  id: SpotlightStatsEnum;
  value: string;
}[] = [
  {
    id: SpotlightStatsEnum.USER_MOST_SONGS,
    value: "Users with most added songs",
  },
  {
    id: SpotlightStatsEnum.USER_MOST_LIKES,
    value: "Users with most likes",
  },
  {
    id: SpotlightStatsEnum.SONG_MOST_LIKES,
    value: "Songs with most likes",
  },
];

export default leaderboardOptionList;
