import axios from "axios";
import { VideoItem } from "@/utils/types/video-item.type";

export const addVideoToFavoriteGroupApi = async (
  user: string | null,
  group: number | null,
  video: VideoItem
) => {
  if (!user) return null;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/favoriteGroups/addVideo`,
      {
        email: user,
        favoriteGroup: group,
        video: video,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch favorite list", error);
    return null;
  }
};

export default addVideoToFavoriteGroupApi;
