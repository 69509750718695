import { FC } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface PlaylistSkeletonProps {
  mode: "light" | "dark";
}

const PlaylistSkeleton: FC<PlaylistSkeletonProps> = ({ mode }) => {
  return (
    <SkeletonTheme
      baseColor={`${mode === "dark" ? "#0B2D39" : "#efefef"}`}
      highlightColor={`${mode === "dark" ? "#061d26" : "#F9F9F9"}`}
    >
      <div className="pl-1 mt-8">
        <Skeleton
          count={3}
          containerClassName="flex gap-x-3"
          style={{ width: "100%", maxWidth: "12.5rem", height: "2.5rem" }}
        />
      </div>
      {Array.from({ length: 5 }, (_, index) => (
        <div
          className={`py-8 xl:flex xl:justify-between xl:items-center ${
            index < 4
              ? `border-b ${
                  mode === "dark"
                    ? "border-accent-gray300"
                    : "border-accent-gray100"
                }`
              : ""
          }`}
          key={index}
        >
          <div className="xl:w-9/12">
            <Skeleton
              style={{
                height: "1.5rem",
                width: "80%",
                maxWidth: "30rem",
                marginBottom: "0.5rem",
              }}
            />
            <Skeleton
              count={3}
              style={{ height: "1.25rem", width: "5rem" }}
              containerClassName="xl:flex xl:gap-x-2"
            />
          </div>

          <Skeleton
            style={{
              height: "1.625rem",
              width: "6.25rem",
              marginTop: "1rem",
            }}
          />
        </div>
      ))}
    </SkeletonTheme>
  );
};

export default PlaylistSkeleton;
