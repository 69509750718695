import DarkModeToggle from "@/Components/UI/DarkModeToggle/DarkModeToggle";
import PlayerToggle from "@/Components/UI/PlayerToggle/PlayerToggle";
import AuthComponent from "@/Components/Header/AuthComponent/AuthComponent";
import Logo from "@/Components/UI/Logo/Logo";

const DesktopMenu = () => {
  return (
    <div className="items-center gap-4 hidden md:flex w-full justify-between">
      <Logo />
      <div className="items-center gap-4 hidden md:flex">
        <DarkModeToggle />
        <PlayerToggle />
        <AuthComponent isMobile={false} />
      </div>
    </div>
  );
};

export default DesktopMenu;
