import { VideoItem } from "@/utils/types/video-item.type";
import axios from "axios";
import { useEffect, useState } from "react";

export type UserFromDB = {
  id: string;
  signName: string;
  avatar: string;
  videos: VideoItem[];
};

export const useFetchHistoryData = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserFromDB[]>([]);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/history`,
          { signal: signal }
        );

        const usersData = response.data.result;

        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching history data: ", error);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return { loading, users };
};
