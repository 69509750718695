import { FC, useContext } from "react";
import Heading from "@/Components/UI/Heading/Heading";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserContext } from "@/context/UserContext/UserContext";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";

interface UserAuthControlsProps {
  isMobile: boolean;
  handleClick: () => void;
}

const UserAuthControls: FC<UserAuthControlsProps> = ({
  isMobile,
  handleClick,
}) => {
  const userContext = useContext(UserContext)!;
  const { mode } = useContext(ThemeContext);

  const { name, picture } = userContext;

  return (
    <>
      <img className="w-9 h-9 rounded-full" src={picture} alt="avatar" />
      <div className="flex flex-col items-start">
        <Heading
          className={`${isMobile && "hyphens-auto"}`}
          type={HeadingTypeEnum.h3_Small}
        >
          {name?.toUpperCase()}
        </Heading>
        <button
          className={`${
            mode === "dark" ? "text-accent-gray200" : "text-accent-gray400"
          } font-nunitoSans text-[12px] font-normal leading-4`}
          onClick={handleClick}
        >
          Log out
        </button>
      </div>
    </>
  );
};

export default UserAuthControls;
