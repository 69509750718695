import { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import AuthComponent from "@/Components/Header/AuthComponent/AuthComponent";
import Logo from "@/Components/UI/Logo/Logo";
interface LoginGateProps {
  extraClassNames?: string;
}

const LoginGate: FC<LoginGateProps> = ({ extraClassNames = "" }) => {
  const { mode } = useContext(ThemeContext);

  const containerStyles =
    mode === "dark"
      ? "bg-background-bgDark100"
      : "bg-background-bgLight100 border-solid border-accent-gray200 border shadow-primary";

  return (
    <div
      className={`${containerStyles} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 md:p-8 flex gap-8 flex-col rounded-md w-full max-w-[220px] max-h-[200px] ${extraClassNames}`}
    >
      <Logo />
      <AuthComponent isMobile={false} />
    </div>
  );
};

export default LoginGate;
