import { VideoItem } from "@/utils/types/video-item.type";
import { Socket } from "socket.io-client";
import toast from "react-hot-toast";
import { MutableRefObject } from "react";

const addMessageToVideo = (
  video: VideoItem,
  user: string | null,
  message: string,
  playerRef: React.MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>
) => {
  if (user) {
    if (playerRef.current) {
      if (serverEndpoint) {
        if (socketRef.current) {
          socketRef.current.emit("addMessage", video, user, message);
        }
      }
    }
  } else {
    toast.error(`You are not authorized!`);
  }
};

export default addMessageToVideo;
