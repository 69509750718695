import { FC, MouseEventHandler, ReactNode, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import TagSizeEnum from "./utils/enums/tag-size.enum";

interface TagProps {
  label: string;
  icon?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: TagSizeEnum;
}

const Tag: FC<TagProps> = ({
  label,
  icon,
  className,
  onClick,
  size = TagSizeEnum.sm,
}) => {
  const { mode } = useContext(ThemeContext);

  const themeStyles = {
    dark: {
      border: "accent-gray300",
      activeBorder: "focus:border-accent-gray100",
      text: "text-neutral-white font-nunitoSans font-normal",
    },
    light: {
      border: "accent-gray100",
      activeBorder: "focus:border-accent-gray300",
      text: "text-primary-blackPetrol font-nunitoSans font-normal",
    },
  };

  const currentTheme = themeStyles[mode];

  return (
    <span
      className={`inline-flex items-center ml-auto leading-3 md:leading-4 rounded-md ${size} ${
        currentTheme.text
      } border border-${currentTheme.border} w-fit ${
        currentTheme.activeBorder
      } ${className} ${onClick ? "cursor-pointer" : ""} ${
        icon ? "gap-x-1.5" : ""
      }`}
      {...(onClick && { onClick: onClick })}
    >
      {icon} {label}
    </span>
  );
};

export default Tag;
