import { FC, useContext } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import LoginGate from "@/Components/Header/LoginGate/LoginGate";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import DesktopSkeleton from "./DesktopSkeleton/DesktopSkeleton";
import MobileSkeleton from "./MobileSkeleton/MobileSkeleton";

const HeaderSkeleton: FC = () => {
  const { mode } = useContext(ThemeContext);
  return (
    <SkeletonTheme
      baseColor={`${mode === "dark" ? "#0B2D39" : "#efefef"}`}
      highlightColor={`${mode === "dark" ? "#061d26" : "#F9F9F9"}`}
    >
      <DesktopSkeleton />
      <MobileSkeleton />
      <LoginGate extraClassNames="hidden" />
    </SkeletonTheme>
  );
};

export default HeaderSkeleton;
