/* eslint-disable no-useless-escape */
function linkify(inputText) {
  var replacedText, replacePattern;

  // URLs starting with http://, https://, or ftp://
  replacePattern =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern,
    '<a href="$1" target="_blank" style="text-decoration: underline;">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern,
    '$1<a href="http://$2" target="_blank" style="text-decoration: underline;">$2</a>'
  );

  return replacedText;
}
export default linkify;
