import {
  BronzeAwardIcon,
  GoldAwardIcon,
  SilverAwardIcon,
} from "@/assets/svg/svg";
import { FC } from "react";

interface AwardIconProps {
  position: "gold" | "silver" | "bronze";
}

const AwardIcon: FC<AwardIconProps> = ({ position }) => {
  return (
    <div className="absolute right-0 top-0 w-[160px] h-[160px] md:w-[200px] md:h-[200px]">
      {position === "gold" && <GoldAwardIcon />}
      {position === "silver" && <SilverAwardIcon />}
      {position === "bronze" && <BronzeAwardIcon />}
    </div>
  );
};

export default AwardIcon;
