import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import axios from "axios";

export const editFavoriteGroupNameApi = async (
  user: string | null,
  group: GroupsInterface
) => {
  if (!user) return null;

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/favoriteGroups`,
      {
        email: user,
        favoriteGroup: group,
      }
    );
    return response;
  } catch (error) {
    console.error("Failed to fetch favorite list", error);
    return null;
  }
};

export default editFavoriteGroupNameApi;
