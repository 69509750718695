enum TooltipTypeEnum {
  MAIN = "main",
  PLAYERLIST = "playerlist",
  FAVORITE = "favorite",
  SEARCH = "search",
  SPOTLIGHT = "spotlight",
  LEADERBOARDLIST = "leaderboard",
  HISTORY = "history",
}

export default TooltipTypeEnum;
