import { FC } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@/assets/svg/svg";
import { FakeSelectProps } from "@/utils/types/fake-select.type";

const FakeSelectSelectedOption: FC<FakeSelectProps> = ({
  handleClick,
  currentTheme,
  dropdownExpanded,
  selected,
}) => {
  return (
    <div
      onClick={handleClick}
      className={`flex justify-between cursor-pointer border 
  rounded-md w-full h-9 font-nunitoSans bg-${
    currentTheme.bg
  } py-2 ps-3 pe-4 focus:outline-none ${
        currentTheme.activeBorder
      } focus:shadow-none focus:ring-0 ${
        dropdownExpanded
          ? `outline-none shadow-none ring-0 ${currentTheme.activeDropdown} rounded-b-none`
          : `border-${currentTheme.border}`
      }`}
    >
      <span
        className={`${currentTheme.text} text-[14px] font-normal leading-5`}
      >
        {selected}
      </span>
      <span className="inline-flex items-center mr-[-10px]">
        {dropdownExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </span>
    </div>
  );
};

export default FakeSelectSelectedOption;
