import { useMemo, useState } from "react";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import { VideoItem } from "@/utils/types/video-item.type";
import useVideosWithoutGroup from "./useVideosWithoutGroup";

const useSetDisplayedVideos = (
  selectedGroup: GroupsInterface,
  favoriteUserList: VideoItem[],
  filterByTitle: boolean,
  groups: GroupsInterface[]
) => {
  const videosWithoutGroup = useVideosWithoutGroup(groups, favoriteUserList);

  const chooseInitialGroup = () => {
    switch (selectedGroup.id) {
      case 1:
        return favoriteUserList;
      case 2:
        return [...videosWithoutGroup];
      default:
        return selectedGroup.videos;
    }
  };

  const [videosToShow, setVideosToShow] = useState<VideoItem[]>(
    chooseInitialGroup()
  );

  const reversedVideos = useMemo(() => {
    const reversedVideoList = [...videosToShow].reverse();

    if (filterByTitle) {
      reversedVideoList.sort((a, b) => a.title.localeCompare(b.title));
    }

    return reversedVideoList;
  }, [videosToShow, filterByTitle]);

  const resetVideosList = () => {
    setVideosToShow(chooseInitialGroup());
  };

  return {
    videosToShow,
    setVideosToShow,
    reversedVideos,
    resetVideosList,
  };
};

export default useSetDisplayedVideos;
