import axios from "axios";

export const getFavoriteListApi = async (user: string | null) => {
  if (!user) return null;

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/favoriteList`,
      { params: { email: user } }
    );
    return response.data.favoriteVideos;
  } catch (error) {
    console.error("Failed to fetch favorite list", error);
    return null;
  }
};

export default getFavoriteListApi;
