import React, { FC, ReactNode, createContext, useState } from "react";

export interface IUserContext {
  user: string | null;
  picture?: string;
  role?: string;
  name?: string;
  userToken?: string | null;
  setUser: (user: string | null) => void;
  setPicture: (picture: string | undefined) => void;
  setRole: (role: string | undefined) => void;
  setName: (name: string | undefined) => void;
  isUserTokenRight: boolean;
  setIsUserTokenRight: (isUserTokenRight: boolean) => void;
}

export const UserContext = createContext<IUserContext | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({
  children,
}: UserProviderProps) => {
  const [user, setUser] = useState<string | null>(null);
  const [picture, setPicture] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>("Anonymous");
  const [isUserTokenRight, setIsUserTokenRight] = useState<boolean>(true);
  const userToken = localStorage.getItem("jwt");

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        picture,
        setPicture,
        role,
        setRole,
        name,
        setName,
        userToken,
        isUserTokenRight,
        setIsUserTokenRight,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
