import { VideoItem } from "../types/video-item.type";

export const toggleFavoriteApi = (user: string | null, video: VideoItem) => {
  return fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/favorite/toggle`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: user, video }),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
};
