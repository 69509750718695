import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import { ChangeEvent, FC } from "react";

interface YouTubeSearchFormProps {
  searchYouTube: (
    e: React.FormEvent<HTMLFormElement> | undefined
  ) => Promise<void>;
  searchTerm: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const YouTubeSearchForm: FC<YouTubeSearchFormProps> = ({
  searchYouTube,
  searchTerm,
  handleChange,
}) => {
  return (
    <form onSubmit={searchYouTube} className="flex gap-2 flex-col">
      <SearchInput
        searchTerm={searchTerm}
        handleChange={handleChange}
        placeholder={"Search of music or paste URL ..."}
      />
      <DefaultButton
        type="submit"
        disabled={searchTerm.length < 1}
        showTooltip={true}
        tooltipId="searchButton"
        tooltipContent={"Please enter a search query"}
      >
        SEARCH
      </DefaultButton>
    </form>
  );
};

export default YouTubeSearchForm;
