import { FC, useContext } from "react";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import AwardIcon from "./AwardIcon/AwardIcon";
import SpotlightItemContent from "./SpotlightItemContent/SpotlightItemContent";

interface SpotlightItemProps {
  item: UserSpotlightItem | VideoItem;
  position: "gold" | "silver" | "bronze";
  metricText: string;
}

const SpotlightItem: FC<SpotlightItemProps> = ({
  item,
  position,
  metricText,
}) => {
  const { mode } = useContext(ThemeContext);

  const themeStyles = {
    dark: {
      gold: "bg-background-bgDarkGold",
      silver: "bg-background-bgDarkSilver",
      bronze: "bg-background-bgDarkBronze",
      text: "text-neutral-white",
    },
    light: {
      gold: "bg-background-bgLightGold",
      silver: "bg-background-bgLightSilver",
      bronze: "bg-background-bgLightBronze",
      text: "text-primary-blackPetrol",
    },
  };

  const currentTheme = themeStyles[mode];
  const currentPosition = themeStyles[mode][position];

  return (
    <div
      className={`${currentTheme.text} ${currentPosition} p-6 md:p-8 rounded-lg h-full relative overflow-hidden`}
    >
      <AwardIcon position={position} />
      <SpotlightItemContent item={item} metricText={metricText} />
    </div>
  );
};

export default SpotlightItem;
