import React, { useContext, useState } from "react";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import { UserContext } from "@/context/UserContext/UserContext";
import { FavoriteContext } from "@/context/FavoriteContext/FavoriteContext";
import { VideoItem } from "@/utils/types/video-item.type";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import YouTubeSearchForm from "./YouTubeSearchForm/YoutubeSearchForm";
import YouTubeSearchResult from "./YouTubeSearchResult/YouTubeSearchResult";
import useSearchYoutube from "./utils/hooks/useSearchYoutube";

const YouTubeSearch: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [results, setResults] = useState<VideoItem[]>([]);

  const userContext = useContext(UserContext);
  const favoriteContext = useContext(FavoriteContext);

  if (!userContext || !favoriteContext) {
    throw new Error("Header must be used within a UserProvider");
  }

  const { user } = userContext;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const searchYouTube = useSearchYoutube({ searchTerm, setResults });

  return (
    <div className="p-6 md:p-8 flex gap-2 flex-col">
      {user ? (
        <>
          <YouTubeSearchForm
            searchYouTube={searchYouTube}
            searchTerm={searchTerm}
            handleChange={handleChange}
          />
          <div>
            {results.map((result, index) => (
              <div
                key={result.id}
                className={`flex flex-col-reverse md:flex-row-reverse gap-4 md:items-center md:justify-between md:gap-8 py-6 ${
                  results.length - 1 !== index
                    ? "border-b border-accent-gray300"
                    : ""
                }`}
              >
                <YouTubeSearchResult result={result} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <Paragraph type={ParagraphTypeEnum.p1_Small}>
          Log in to access all the features.
        </Paragraph>
      )}
    </div>
  );
};

export default YouTubeSearch;
