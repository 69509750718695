import { VideoItem } from "@/utils/types/video-item.type";
import { MutableRefObject } from "react";
import { Socket } from "socket.io-client";

const onVideoSelect = (
  video: VideoItem,
  serverEndpoint: string | undefined,
  socketRef: MutableRefObject<Socket | null>
) => {
  if (!serverEndpoint) {
    console.error("SERVER_ENDPOINT is not defined");
    return;
  }

  if (socketRef.current) {
    socketRef.current.emit("addVideo", video);
  }
};

export default onVideoSelect;
