import { FC } from "react";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import SpotlightItem from "./SpotlightItem/SpotlightItem";

interface LeaderboardSpotlightProps {
  items: (UserSpotlightItem | VideoItem)[];
  metricText: string;
}

const LeaderboardSpotlight: FC<LeaderboardSpotlightProps> = ({
  items,
  metricText,
}) => {
  const positions = ["gold", "silver", "bronze"];

  return (
    <div className="grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-8">
      {items.map((item, id) => (
        <div key={id}>
          <SpotlightItem
            item={item}
            position={positions[id] as "gold" | "silver" | "bronze"}
            metricText={metricText}
          />
        </div>
      ))}
    </div>
  );
};

export default LeaderboardSpotlight;
