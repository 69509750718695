import ActionBar from "@/Components/UI/ActionBar/ActionBar";
import TooltipTypeEnum from "@/utils/enums/tooltip-type.enum";
import { UserSpotlightItem } from "@/utils/types/user.type";
import { VideoItem } from "@/utils/types/video-item.type";
import { FC } from "react";

interface SpotlightItemContentProps {
  item: VideoItem | UserSpotlightItem;
  metricText: string;
}

const SpotlightItemContent: FC<SpotlightItemContentProps> = ({
  item,
  metricText,
}) => {
  return (
    <div className="relative flex flex-col z-10 h-full">
      {(item as UserSpotlightItem).avatar && (
        <img
          src={(item as UserSpotlightItem).avatar}
          alt={(item as UserSpotlightItem).name}
          className="w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem] rounded-full mb-3 "
        />
      )}
      <h2 className="md:text-[20px] font-bold mb-3 md:mb-auto">
        {(item as UserSpotlightItem).name || (item as VideoItem).title!}
      </h2>
      <div className="flex flex-wrap flex-col md:flex-row justify-between gap-x-6 gap-y-3">
        <p>
          {metricText}{" "}
          <b>
            {(item as UserSpotlightItem).value || (item as VideoItem).likes!}
          </b>
        </p>
        {(item as VideoItem).likes && (
          <div className="flex items-center items-center gap-x-4">
            <ActionBar
              video={item as VideoItem}
              tooltipId={TooltipTypeEnum.SPOTLIGHT}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpotlightItemContent;
