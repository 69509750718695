import { VideoItem } from "@/utils/types/video-item.type";
import { useState, useEffect } from "react";
import { UserFromDB } from "./useHistoryData";

const useTransformedOptions = (users: UserFromDB[]) => {
  const [dropdownOptions, setDropdownOptions] = useState<
    { id: string | number; value: string }[]
  >([]);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>("");
  const [videos, setVideos] = useState<VideoItem[]>([]);

  useEffect(() => {
    if (users && users.length > 0) {
      const transformedOptions = users.reduce((acc, user) => {
        if (
          user.signName &&
          user.signName !== "undefined" &&
          user.id !== null
        ) {
          acc.push({
            id: user.id,
            value: user.signName,
          });
        }
        return acc;
      }, [] as { id: string | number; value: string }[]);
      setDropdownOptions(transformedOptions);
      setSelectedUserId(users[0].id);

      const selectedVideos = users.find((user, index) => index === 0);
      setVideos(selectedVideos?.videos!);
    }
  }, [users]);

  return {
    dropdownOptions,
    selectedUserId,
    videos,
    setSelectedUserId,
    setVideos,
  };
};

export default useTransformedOptions;
