import { Socket } from "socket.io-client";

const handleSyncDB = (
  playerRef: React.MutableRefObject<{
    internalPlayer: {
      playVideo: () => void;
      pauseVideo: () => void;
      setVolume: (value: number) => void;
      getPlayerState: () => Promise<number>;
    };
  } | null>,
  serverEndpoint: string | undefined,
  socketRef: React.MutableRefObject<Socket | null>,
  isPlaying: boolean
) => {
  if (playerRef.current) {
    // get current play state from server
    if (serverEndpoint) {
      if (socketRef.current) {
        socketRef.current.emit("requestCurrentTime");
        socketRef.current.emit("togglePlayPause", !isPlaying);
        socketRef.current.on("currentVideoTime", (time) => {
          if (playerRef.current) {
            (playerRef.current.internalPlayer as any).seekTo(time / 1000 - 2);
            playerRef.current.internalPlayer.playVideo();
          }
        });
      }
    }
  }
};

export default handleSyncDB;
